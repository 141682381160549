import React, { useEffect } from 'react';
import axios from 'axios';
import { load } from 'cheerio';
import { useState } from 'react';

// const Scrape = () => {
//   const [tableData, setTableData] = useState([]);
//   useEffect(() => {
//     const fetchAndSetTableData = async () => {
//       try {
//         const response = await axios.get('http://43.156.68.222/'); // Replace with the URL of the website you want to scrape
//         const html = response.data;

//         const $ = load(html);

//         const tableData = [];

//         // Assuming the table has a specific ID, replace 'tableId' with the actual ID of the table
//         $('.ant-table .ant-table-thead .ant-table-tbody tr').each(
//           (index, element) => {
//             const row = [];
//             $(element)
//               .find('td')
//               .each((i, el) => {
//                 row.push($(el).text().trim());
//               });
//             tableData.push(row);
//           }
//         );

//         //    return tableData;
//         setTableData(tableData);
//       } catch (error) {
//         console.error('Error:', error);
//       }
//     };
//     // Fetch table data every 5 minutes (300000 milliseconds)
//     const interval = setInterval(fetchAndSetTableData, 300000);

//     // Fetch the initial table data immediately
//     fetchAndSetTableData();

//     // Clear the interval on component unmount to prevent memory leaks
//     return () => clearInterval(interval);
//   }, []);
//   console.log('tableData', tableData);
//   return <div>Scrape</div>;
// };
// import { Builder } from 'selenium-webdriver';
// // Create a new WebDriver instance
// const driver = await new Builder().forBrowser('chrome').build();

const Scrape = () => {
  // console.log('Driver', driver);
  useEffect(() => {
    const getData = async () => {
      const res = await fetch('http://43.156.68.222/', {
        method: 'GET',
        mode: 'cors',
      });
      console.log('res', res);
    };
    getData();
  }, []);
  return (
    <>
      <div>hello</div>
    </>
  );
};

export default Scrape;
