// import ReactLoading from 'react-loading';
import React, { useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { Tooltip } from 'react-tooltip';
import thermometer from '../assets/icons/thermometer.png';
import humidity from '../assets/icons/humidity.png';
import CountUp from 'react-countup';
import close from '../assets/icons/close.png';
import { Table } from '@nextui-org/react';

const model_styles = {
  position: 'fixed',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1500,
  borderRadius: '12px',
  height: 'auto',
  width: 'auto',
};

const overlay_style = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  height: '100%',
  width: '100%',
  backgroundColor: '#D9D9D980',
  zIndex: 1000,
};

const WeightModal = ({
  isOpenWeight,
  handleCloseWeight,
  tent,
  orderList,
  day,
}) => {
  // const [isOpenWeight, setIsOpen] = useState(false);
  //   const handleCloseWeight = () => {
  //     setIsOpen(false);
  //   };

  let dateWiseOrder = orderList
    ?.filter(order => order?.deviceNum === parseInt(tent?.name)) // .split(' ')[1]))
    .filter(dworder => dworder?.date.slice(0, 10) === day);
  console.log('datawiseorder', dateWiseOrder, orderList);

  let breakfast = dateWiseOrder?.filter(
    data =>
      // console.log('breakfast', data?.date.slice(11))
      '02:00:00' <= data?.date.slice(11) && '10:00:00' >= data?.date.slice(11)
  );
  let launch = dateWiseOrder?.filter(
    data =>
      '12:00:00' <= data?.date.slice(11) && '15:00:00' >= data?.date.slice(11)
  );
  let dinner = dateWiseOrder?.filter(
    data =>
      '18:00:00' <= data?.date.slice(11) && '23:59:00' >= data?.date.slice(11)
  );
  console.log(breakfast, launch, dinner);

  let bAccepted = breakfast?.filter(order => order.weight >= 300);
  let lAccepted = launch?.filter(order => order.weight >= 400);
  let dAccepted = dinner?.filter(order => order.weight >= 400);

  // console.log(bAccepted);

  const bTotalWt = bAccepted?.reduce((sum, order) => sum + order.weight, 0);
  const lTotalWt = lAccepted?.reduce((sum, order) => sum + order.weight, 0);
  const dTotalWt = dAccepted?.reduce((sum, order) => sum + order.weight, 0);

  const bAvgWt = bAccepted?.length > 0 ? bTotalWt / bAccepted?.length : 0;
  const lAvgWt = lAccepted?.length > 0 ? lTotalWt / lAccepted?.length : 0;
  const dAvgWt = dAccepted?.length > 0 ? dTotalWt / dAccepted?.length : 0;
  var v = 0;
  const totalAccepted =
    bAccepted?.length > 0
      ? bAccepted?.length
      : 0 + lAccepted?.length > 0
      ? lAccepted?.length
      : 0 + dAccepted?.length > 0
      ? dAccepted?.length
      : 0;
  // check bAccepted, lAccepted, dAccepted is not empty then increment the value of v
  if (bAccepted?.length > 0) {
    v++;
  }
  if (lAccepted?.length > 0) {
    v++;
  }
  if (dAccepted?.length > 0) {
    v++;
  }

  const avgWeight = v > 0 ? (bAvgWt + lAvgWt + dAvgWt) / v : 0;
  // console.log('weight', bAvgWt, lAvgWt, dAvgWt, bAccepted, v);

  //   console.log('in sensormodal', siteData);

  return (
    <>
      {isOpenWeight ? (
        <>
          <div onClick={handleCloseWeight} style={overlay_style} />
          {/* <div className="flex justify-center items-center"> */}
          <div
            //onClick={handleClose}
            style={model_styles}
            className="flex md:px-[300px] px-[150px] relative justify-center items-center"
          >
            {/* Rest of the content */}
            <div className="absolute w-full h-full justify-center items-center">
              {/* <SensorInfo siteData={siteData} /> */}
              <div className="bg-white p-3 relative rounded-3xl">
                <div
                  className="absolute top-0 right-0 p-2 cursor-pointer"
                  onClick={handleCloseWeight}
                >
                  <img src={close} className="h-[30px] m-[10px]" alt="" />
                </div>
                <h4 className="text-center mb-3 text-blue-800 text-[18px]">
                  {tent.name} مركز
                </h4>
                <h4 className="text-center mb-3 text-blue-800 ">
                  {tent.capacity} : اعداد الحجاج
                </h4>
                <Table
                  lined
                  aria-label="."
                  css={{
                    height: 'auto',
                    minWidth: '100%',
                  }}
                >
                  <Table.Header>
                    {/* <Table.Column className="text-blue-800 text-sm">
                      {' '}
                      المجموع{' '}
                    </Table.Column> */}
                    <Table.Column className="text-blue-800 text-sm">
                      {' '}
                      الوجبات المرفوضة{' '}
                    </Table.Column>
                    <Table.Column className="text-blue-800 text-sm">
                      {' '}
                      الوجبات المقبولة
                    </Table.Column>
                    <Table.Column className="text-blue-800 text-sm">
                      {' '}
                      الوزن المتوسط{' '}
                    </Table.Column>
                    <Table.Column></Table.Column>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row key="1">
                      {/* <Table.Cell>
                        <CountUp
                          start={0}
                          end={
                            breakfast?.length >= 0
                              ? Math.ceil(breakfast?.length)
                              : 0
                          }
                          duration={2}
                          separator=","
                        />
                      </Table.Cell> */}
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={
                            breakfast?.length - bAccepted?.length >= 0
                              ? Math.ceil(breakfast?.length - bAccepted?.length)
                              : 0
                          }
                          duration={2}
                          separator=","
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={
                            bAccepted?.length >= 0
                              ? Math.ceil(bAccepted?.length)
                              : 0
                          }
                          duration={2}
                          separator=","
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={bAvgWt >= 0 ? Math.ceil(bAvgWt) : 0}
                          duration={2}
                          separator=","
                        />
                        gm
                      </Table.Cell>
                      <Table.Cell
                        css={{
                          color: '#1d4ed8',
                        }}
                      >
                        الفطور
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row key="2">
                      {/* <Table.Cell>
                        <CountUp
                          start={0}
                          end={
                            launch?.length >= 0 ? Math.ceil(launch?.length) : 0
                          }
                          duration={2}
                          separator=","
                        />
                      </Table.Cell> */}
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={
                            launch?.length - lAccepted?.length >= 0
                              ? Math.ceil(launch?.length - lAccepted?.length)
                              : 0
                          }
                          duration={2}
                          separator=","
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={
                            lAccepted?.length >= 0
                              ? Math.ceil(lAccepted?.length)
                              : 0
                          }
                          duration={2}
                          separator=","
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={lAvgWt >= 0 ? Math.ceil(lAvgWt) : 0}
                          duration={2}
                          separator=","
                        />
                        gm
                      </Table.Cell>
                      <Table.Cell
                        css={{
                          color: '#1d4ed8',
                        }}
                      >
                        الغداء
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row key="3">
                      {/* <Table.Cell>
                        <CountUp
                          start={0}
                          end={
                            dinner?.length >= 0 ? Math.ceil(dinner?.length) : 0
                          }
                          duration={2}
                          separator=","
                        />
                      </Table.Cell> */}
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={
                            dinner?.length - dAccepted?.length >= 0
                              ? Math.ceil(dinner?.length - dAccepted?.length)
                              : 0
                          }
                          duration={2}
                          separator=","
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={
                            dAccepted?.length >= 0
                              ? Math.ceil(dAccepted?.length)
                              : 0
                          }
                          duration={2}
                          separator=","
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={dAvgWt >= 0 ? Math.ceil(dAvgWt) : 0}
                          duration={2}
                          separator=","
                        />
                        gm
                      </Table.Cell>
                      <Table.Cell
                        css={{
                          color: '#1d4ed8',
                        }}
                      >
                        العشاء
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row key="4">
                      {/* <Table.Cell>
                        <CountUp
                          start={0}
                          end={
                            dateWiseOrder?.length >= 0
                              ? Math.ceil(dateWiseOrder?.length)
                              : 0
                          }
                          duration={2}
                          separator=","
                        />
                      </Table.Cell> */}
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={
                            dateWiseOrder?.length - totalAccepted >= 0
                              ? Math.ceil(dateWiseOrder?.length - totalAccepted)
                              : 0
                          }
                          duration={2}
                          separator=","
                        />{' '}
                      </Table.Cell>
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={
                            totalAccepted >= 0 ? Math.ceil(totalAccepted) : 0
                          }
                          duration={2}
                          separator=","
                        />
                      </Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell
                        css={{
                          color: '#1d4ed8',
                        }}
                      >
                        المجموع
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
          </div>
          {/* </div> */}
        </>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default WeightModal;

// export const SensorInfo = ({ siteData }) => {
//   return (
//     <>
//       <div className="bg-white p-3 rounded">
//         <h4 className="text-center mb-3">{tent.name} مركز</h4>
//         <h4 className="text-center mb-3">{tent.capacity} :العدد الفعلي</h4>
//         <table className="w-full table-auto text-center text-sm">
//           <thead>
//             <tr className="text-center">
//               <th style={{ padding: '7px' }}> المجموع </th>
//               <th style={{ padding: '7px' }}> الوجبات المرفوضة </th>
//               <th style={{ padding: '7px' }}> الوجبات المقبولة </th>
//               <th style={{ padding: '7px' }}> الوزن المتوسط </th>
//               <th style={{ padding: '7px' }}></th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr className="text-center">
//               <td className="total">
//                 <CountUp
//                   start={0}
//                   end={breakfast?.length >= 0 ? Math.ceil(breakfast?.length) : 0}
//                   duration={2}
//                   separator=","
//                 />
//               </td>
//               <td className="rejected">
//                 <CountUp
//                   start={0}
//                   end={
//                     breakfast?.length - bAccepted?.length >= 0
//                       ? Math.ceil(breakfast?.length - bAccepted?.length)
//                       : 0
//                   }
//                   duration={2}
//                   separator=","
//                 />
//               </td>

//               <td className="accepted">
//                 <CountUp
//                   start={0}
//                   end={bAccepted?.length >= 0 ? Math.ceil(bAccepted?.length) : 0}
//                   duration={2}
//                   separator=","
//                 />
//               </td>
//               <td className="avg_wt">
//                 <CountUp
//                   start={0}
//                   end={bAvgWt >= 0 ? Math.ceil(bAvgWt) : 0}
//                   duration={2}
//                   separator=","
//                 />
//                 gm
//               </td>
//               <td>الفطور</td>
//             </tr>
//             <tr className="text-center">
//               <td className="total">
//                 <CountUp
//                   start={0}
//                   end={launch?.length >= 0 ? Math.ceil(launch?.length) : 0}
//                   duration={2}
//                   separator=","
//                 />
//               </td>
//               <td className="rejected">
//                 <CountUp
//                   start={0}
//                   end={
//                     launch?.length - lAccepted?.length >= 0
//                       ? Math.ceil(launch?.length - lAccepted?.length)
//                       : 0
//                   }
//                   duration={2}
//                   separator=","
//                 />
//               </td>
//               <td className="accepted">
//                 <CountUp
//                   start={0}
//                   end={lAccepted?.length >= 0 ? Math.ceil(lAccepted?.length) : 0}
//                   duration={2}
//                   separator=","
//                 />
//               </td>

//               <td className="avg_wt">
//                 <CountUp
//                   start={0}
//                   end={lAvgWt >= 0 ? Math.ceil(lAvgWt) : 0}
//                   duration={2}
//                   separator=","
//                 />
//                 gm
//               </td>
//               <td>الغداء</td>
//             </tr>
//             <tr className="text-center">
//               <td className="total">
//                 <CountUp
//                   start={0}
//                   end={dinner?.length >= 0 ? Math.ceil(dinner?.length) : 0}
//                   duration={2}
//                   separator=","
//                 />
//               </td>
//               <td className="rejected">
//                 <CountUp
//                   start={0}
//                   end={
//                     dinner?.length - dAccepted?.length >= 0
//                       ? Math.ceil(dinner?.length - dAccepted?.length)
//                       : 0
//                   }
//                   duration={2}
//                   separator=","
//                 />
//               </td>
//               <td className="accepted">
//                 <CountUp
//                   start={0}
//                   end={dAccepted?.length >= 0 ? Math.ceil(dAccepted?.length) : 0}
//                   duration={2}
//                   separator=","
//                 />
//               </td>

//               <td className="avg_wt">
//                 <CountUp
//                   start={0}
//                   end={dAvgWt >= 0 ? Math.ceil(dAvgWt) : 0}
//                   duration={2}
//                   separator=","
//                 />
//                 gm
//               </td>
//               <td>العشاء</td>
//             </tr>
//             <hr className="w-full border-2" />

//             <tr className="text-center mt-2 pt-2 border-t-2 border-black">
//               <td className="total">
//                 <CountUp
//                   start={0}
//                   end={
//                     dateWiseOrder?.length >= 0
//                       ? Math.ceil(dateWiseOrder?.length)
//                       : 0
//                   }
//                   duration={2}
//                   separator=","
//                 />
//               </td>

//               <td className="rejected">
//                 <CountUp
//                   start={0}
//                   end={
//                     dateWiseOrder?.length - totalAccepted >= 0
//                       ? Math.ceil(dateWiseOrder?.length - totalAccepted)
//                       : 0
//                   }
//                   duration={2}
//                   separator=","
//                 />{' '}
//               </td>
//               <td className="accepted">
//                 <CountUp
//                   start={0}
//                   end={totalAccepted >= 0 ? Math.ceil(totalAccepted) : 0}
//                   duration={2}
//                   separator=","
//                 />
//               </td>

//               <td className="avg_wt"> </td>

//               <td>المجموع</td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//     </>
//   );
// };
