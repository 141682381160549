import React from 'react';
import { useSelector } from 'react-redux';
import getEnvironmentData from '../../util/getEnvironment';
import Header from '../../components/Shared/Header';
import SensorCard from '../../components/SensorCard';

const Arafa = () => {
  const TentData = useSelector(state => state?.global?.TentData);
  const Arafa = useSelector(state => state?.global?.Arafa);
  const arafa = Arafa?.map(tent => tent?.pk);
  const data = TentData?.filter(tent => arafa?.indexOf(tent?.pk) !== -1)
    ?.filter(data => data?.sensor_data?.length > 0)
    ?.map(data => {
      const environment = getEnvironmentData(data?.sensor_data);
      return {
        ...data,
        environment,
      };
    })
    .sort((a, b) => {
      return b.environment.maximumTemp - a.environment.maximumTemp;
    });
  //   const higher = data?.slice(0, 4)?.map(data => {
  //     return data.pk;
  //   });
  //   const lower = data?.slice(-4)?.map(data => {
  //     return data.pk;
  //   });
  // console.log(higher);
  // console.log(lower);
  const finalData = data
    .concat(
      TentData?.filter(
        tent =>
          arafa?.indexOf(tent?.pk) !== -1 && tent?.sensor_data?.length === 0
      )
    )
    .sort((a, b) => {
      return a.pk - b.pk;
    });
  // console.log(higher);
  // console.log(lower);

  console.log('arafa data', finalData);

  return (
    <div className="min-h-screen bg-gray-100 p-5">
      <Header title={'عرفة'} />
      <div className="tentList-container flex flex-row-reverse justify-center flex-wrap gap-3">
        {finalData ? (
          finalData
            ?.filter(tent => !tent?.is_arafa)
            ?.map((tent, index) => (
              <SensorCard key={tent.pk} siteData={tent} is_arafa={true} />
            ))
        ) : (
          <p>Loading</p>
        )}
      </div>
    </div>
  );
};

export default Arafa;
