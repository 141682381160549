import { Link as ChakraLink, Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
  RiHome4Fill,
  RiHome4Line,
  RiAdminFill,
  RiFullscreenFill,
  RiTableFill,
  RiLinksLine,
  RiTableLine,
  RiCalendarTodoFill,
  RiCalendarTodoLine,
  RiAccountPinCircleFill,
  RiAccountPinCircleLine,
} from 'react-icons/ri';

import { IoMdLink } from 'react-icons/io';

import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { deleteCookie, getCookie } from '../../lib/Cookies';
import Navbar from '../../components/Shared/Navbar';
import Sidebar from '../../components/Shared/Sidebar';
import MainNavbar from '../../components/Shared/Navbar';
import { useGetCameraListQuery, useGetOrderListQuery, useGetSensorListQuery, useGetTentListQuery } from '../../redux/api';
import { useSelector } from 'react-redux';
import { Box, CircularProgress } from '@mui/material';

export default function Main({ children }) {
  const [path, setPath] = useState('');
  const TentData = useSelector(state => state?.global?.TentData);
  const location = useLocation();
  useEffect(() => {
    setPath(location.pathname.split('/')[1]);
  }, [location]);

  const heads = [
    {
      path: 'home',
      name: 'Home',
      iconActive: RiHome4Fill,
      iconInactive: RiHome4Line,
    },
    {
      type: 'accor',
      name: 'Folding',
      iconActive: RiTableFill,
      iconInactive: RiTableLine,
      items: [
        {
          path: '',
          name: 'Home',
          iconActive: RiHome4Fill,
          iconInactive: RiHome4Line,
        },
        {
          path: 'Links',
          name: 'Links',
          iconActive: IoMdLink,
          iconInactive: RiLinksLine,
        },
        {
          path: 'Notices',
          name: 'Notices',
          iconActive: RiCalendarTodoFill,
          iconInactive: RiCalendarTodoLine,
        },
        {
          path: 'Peoples',
          name: 'Peoples',
          iconActive: RiAccountPinCircleFill,
          iconInactive: RiAccountPinCircleLine,
        },
      ],
    },
  ];

  const {
    data: data1,
    isError: isError1,
    isLoading: isLoading1,
  } = useGetTentListQuery(undefined, {
    pollingInterval: 60000,
  });
  // const {data : data2, isError: isError2, isLoading : isLoading2} = useGetCameraListQuery(undefined, {
  //   pollingInterval: 60000,
  // });
  const {
    data: data3,
    isError: isError3,
    isLoading: isLoading3,
  } = useGetOrderListQuery(undefined, {
    pollingInterval: 60000,
  });
  const {
    data: data4,
    isError: isError4,
    isLoading: isLoading4,
  } = useGetSensorListQuery(undefined, {
    pollingInterval: 60000,
  });
  console.log(data1);
  return (
    TentData ? <Flex w={'100%'} flexFlow={'column'} bg="#F7F7F7">
      {/* Topbar */}
      <MainNavbar />
      <div className="flex">
        <div className="grow mr-[4.5rem] sm:mr-0 bg-[#f3f3f3]">
          <Outlet />
        </div>
        <div className={`flex-none h-[90vh] sm:sticky fixed right-0 top-[11vh] bg-[#F7F7F7] z-[10000]`}>
          <Sidebar />
        </div>
      </div>
    </Flex> : (
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )
  );
}

//  <Flex w="full" h="88%">
//    <Flex flexFlow={'column'} width={'15%'} boxShadow={'lg'} pt={10}>
//      {JSON.parse(getCookie('user')).data.is_superuser && (
//        <ChakraLink
//          className="flat"
//          key={Math.random()}
//          as={Link}
//          py={3}
//          to={`/adminsite/userregistration`}
//          bg={'transparent'}
//          color={'text.light'}
//          _hover={{ color: 'text.dark' }}
//        >

//          <Flex alignItems={'center'} justifyContent={'start'} pl={3}>
//            <Icon as={RiAdminFill} h={8} w={8} mr={3} />
//            <Text fontWeight={'semibold'} fontSize={'lg'}>
//              Admin Panel
//            </Text>
//          </Flex>
//        </ChakraLink>
//      )}
//    </Flex>
//  </Flex>;

// {
//   heads.map((el, i) =>
//     el.type === 'accor' ? (
//       <Accordion allowMultiple borderColor={'bg.light'}>
//         <AccordionItem>
//           <AccordionButton>
//             <Flex
//               className="flat"
//               key={Math.random()}
//               py={3}
//               bg={'transparent'}
//               color={'ash.500'}
//               justifyContent={'space-around'}
//               width={'full'}
//             >
//               <Text fontWeight={'semibold'} fontSize={'lg'}>
//                 {el.name}
//               </Text>
//               <AccordionIcon h={6} w={6} />
//             </Flex>
//           </AccordionButton>
//           <AccordionPanel
//             borderBottom={'1px'}
//             borderColor={'ash.600'}
//           >
//             {el.items.map((e, i) => (
//               <ChakraLink
//                 className="flat"
//                 key={Math.random()}
//                 as={Link}
//                 py={3}
//                 to={`/${e.path}`}
//                 bg={'transparent'}
//                 borderBottomWidth={'3px'}
//                 borderBottomColor={
//                   e.path === path
//                     ? 'border.light'
//                     : 'transparent'
//                 }
//                 color={
//                   e.path === path ? 'text.light' : 'ash.500'
//                 }
//                 _hover={
//                   e.path === path ? '' : { color: 'ash.300' }
//                 }
//               >
//                 <Flex
//                   alignItems={'center'}
//                   justifyContent={'start'}
//                   pl={3}
//                 >
//                   <Icon
//                     as={
//                       e.path === path
//                         ? e.iconActive
//                         : e.iconInactive
//                     }
//                     h={8}
//                     w={8}
//                     mr={3}
//                   />
//                   <Text fontWeight={'semibold'} fontSize={'lg'}>
//                     {e.name}
//                   </Text>
//                 </Flex>
//               </ChakraLink>
//             ))}
//           </AccordionPanel>
//         </AccordionItem>
//       </Accordion>
//     ) : (
//       <ChakraLink
//         className="flat"
//         key={Math.random()}
//         as={Link}
//         py={3}
//         to={`/${el.path}`}
//         bg={'transparent'}
//         borderBottomWidth={'3px'}
//         borderBottomColor={
//           el.path === path ? 'text.dark' : 'transparent'
//         }
//         color={el.path === path ? 'text.dark' : 'text.light'}
//         _hover={el.path === path ? '' : { color: 'ash.300' }}
//       >
//         <Flex
//           alignItems={'center'}
//           justifyContent={'start'}
//           pl={3}
//         >
//           <Icon
//             as={
//               el.path === path ? el.iconActive : el.iconInactive
//             }
//             h={8}
//             w={8}
//             mr={3}
//           />
//           <Text fontWeight={'semibold'} fontSize={'lg'}>
//             {el.name}
//           </Text>
//         </Flex>
//       </ChakraLink>
//     )
//   );
// }
