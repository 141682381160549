import { Navigate, Outlet } from 'react-router-dom';
import { getCookie } from '../lib/Cookies';
// import { getCookie } from '../lib/cookie';
// import { useAuth } from '../server/auth.context';

export default function PublicRoute() {


  const sensor_update_permission = getCookie('user')
  ? JSON.parse(getCookie('user'))?.data?.sensor_update_permission
  : false;

  //const { currentUser } = useAuth();

//   if(permission){
//     return <Navigate to="/airquality" />;
// }

  if (sensor_update_permission) {
    console.log("sensor_update_permission ",sensor_update_permission)
    return <Navigate to="/airquality" />;
  } else if (getCookie('user'))
  {
    console.log("getCookie('user') ",getCookie('user'))
    return <Navigate to="/home" />;
  } 
  return <Outlet />;
} 
