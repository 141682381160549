import React, { useEffect, useState } from 'react';
import OrderCard from '../../components/OrderCard2';
import { tentListAPI, orderListAPI } from '../../lib/apis';
import TentInfoCard from '../../components/tentInfoCard';
import CountUp from 'react-countup';
import { StyledBackdropLayer } from '@nextui-org/react';
import WeightModal from '../../components/WeightModal3';
import { Card } from '@nextui-org/react';
import scale from './scale.png';
import { useSelector } from 'react-redux';
import Header from '../../components/Shared/Header';

const Weights = () => {
  const tentList = useSelector(state => state.global.TentList);
  const orderList = useSelector(state => state.global.OrderList);
  // const [tentList, setTentList] = useState([]);
  const [tent, setTent] = useState('');
  const [today, setToday] = useState('');
  const [yesterDay, setYesterDay] = useState('');
  const [day, setDay] = useState('');
  // const [orderList, setOrderList] = useState([]);
  const [isOpenWeight, setIsOpenWeight] = useState(false);

  const handleCloseWeight = () => {
    setIsOpenWeight(false);
  };
  // console.log(today, yesterDay, day);
  // console.log('weight', tentList);

  const setDate = () => {
    // Get current date
    //const currentDate = new Date(Date.UTC())
    //const currentDate = new Date().toLocaleString('en-SA',{timeZone: 'Asia/Riyadh'});

    const now = new Date();
    const isoCurrentDate = now.toLocaleDateString('sv', {
      timeZone: 'Asia/Riyadh',
    });

    // console.log(isoCurrentDate);
    // Get yesterday's date
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const isoYesterday = yesterday.toLocaleDateString('sv', {
      timeZone: 'Asia/Riyadh',
    });

    // Format the dates
    //const isoCurrentDate = currentDate.slice(0, 10);
    //const isoYesterday = yesterday.toISOString().slice(0, 10);
    // console.log('isoCurrentDate ', isoCurrentDate);
    // console.log('isoYesterday ', isoYesterday);
    setToday(isoCurrentDate);
    setYesterDay(isoYesterday);
    setDay(isoCurrentDate);
  };

   useEffect(() => {
      let interval;

  //     const fetchData = async () => {
  //       await tentListAPI(setTentList);
  //     };

      // const startFetchingData = () => {
  //       fetchData();
        // interval = setInterval(fetchData, 60000);
  //     };

  //     const stopFetchingData = () => {
    setDate();
  //       clearInterval(interval);
  //     };

  //     startFetchingData();


  //     return () => {
  //       stopFetchingData();
  //     };
    }, []);
  //  console.log('weight', tentList);
  // useEffect(() => {
  //   let interval;

  //   const fetchData = async () => {
  //     // console.log('calling fetchdata');
  //     await orderListAPI(setOrderList);
  //   };

  //   const startFetchingData = () => {
  //     fetchData(); // Fetch initial
  //     interval = setInterval(fetchData, 6000);
  //   };

  //   const stopFetchingData = () => {
  //     clearInterval(interval);
  //   };

  //   startFetchingData();

  //   return () => {
  //     stopFetchingData();
  //   };
  // }, []);


  console.log(today)
  // 2023-06-22T11:15:13

  return (
    <div className="min-h-screen bg-gray-100 px-5">
      <div className="w-full">
        <h1 className="w-full text-center text-4xl pt-4 text-[#d68e35]">
          وزن الوجبه
        </h1>
      </div>
      {/* <div className="sort-container flex justify-end">
        <div className="bg-white p-3 rounded">
          <p>Sort by: Staying</p>
        </div>
      </div> */}
      <div className="sort-container flex justify-end mb-5">
        <div className="bg-white px-3 rounded">
          <select
            onChange={e => {
              setDay(e.target.value);
            }}
          >
            <option value={today}>Today</option>
            <option value={yesterDay}>Yesterday</option>
          </select>
        </div>
      </div>
      <div className="tentList-container items-end flex flex-row-reverse justify-center flex-wrap gap-3">
        {isOpenWeight && (
          <WeightModal
            tent={tent}
            orderList={orderList}
            day={day}
            isOpenWeight={isOpenWeight}
            handleCloseWeight={handleCloseWeight}
            setIsOpenWeight={setIsOpenWeight}
          />
        )}

        {tentList?.filter(tent => !tent?.is_arafa)?.map(tent => (
          <>
            <Card
              isPressable
              isHoverable
              variant="bordered"
              css={{ mw: '170px' }}
              //className='basis-[calc(50%-0.75rem)] sm:basis-[calc(33%-0.75rem)] md:basis-[calc(25%-0.75rem)] lg:basis-[calc(5%-0.75rem)]'
              onClick={() => {
                setTent(tent);
                setIsOpenWeight(true);
                console.log(tent);
              }}
            >
              {/* staying */}
              {/* <p className="text-[26px]">اسم المركز</p> */}
              <div className="w-full  justify-between text-blue-800 items-end">
                <div className="flex gap-1 items-center justify-end">
                    <p className="text-[20px]">{tent?.name} مركز</p>
                    <img src={scale} className="h-[30px] m-[10px]" alt="" />
                </div>
                <div className="">
                  <OrderCard
                    tent={tent}
                    orderList={orderList}
                    day={day}
                    isTitleShow={false}
                    fontSize={'16px'}
                  ></OrderCard>
                </div>
              </div>
            </Card>
          </>

          // <OrderCard
          //  tent={tent}
          //  orderList={orderList}
          //  day={day}
          //  ></OrderCard>
        ))}
      </div>
    </div>
  );
};

export default Weights;
