// import ReactLoading from 'react-loading';
import React, { useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { Tooltip } from 'react-tooltip';
import thermometer from '../assets/icons/thermometer.png';
import humidity from '../assets/icons/humidity.png';
import { Table } from '@nextui-org/react';
import close from '../assets/icons/close.png';
import moment from 'moment';
import 'moment/locale/ar';
import 'moment-timezone';

const model_styles = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1000,
  borderRadius: '12px',
  height: 'auto',
  width: 'auto',
};

const overlay_style = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: '#D9D9D980',
  zIndex: 1000,
};

const SensorModal = ({ setIsOpen, isOpen, handleClose, siteData, id }) => {
  // console.log('in sensormodal', siteData);

  // useEffect(() => {
  //   let interval;

  //   const fetchData = async () => {
  //     await tentDataAPI(tent, setSiteData);
  //   };

  //   // const startFetchingData = () => {
  //   //   interval = setInterval(fetchData, 60000);
  //   // };

  //   // const stopFetchingData = () => {
  //   //   clearInterval(interval);
  //   // };

  //   // startFetchingData();

  //   // return () => {
  //   //   stopFetchingData();
  //   // };
  //   tent && fetchData();
  // }, [tent]);

  // console.log(data)

  return (
    <>
      {isOpen ? (
        <>
          <div onClick={handleClose} style={overlay_style} />
          <div
            //onClick={handleClose}
            style={model_styles}
            className="flex flex-col justify-center px-[50px] py-[30px]  items-center relative"
          >
            <div
              className="absolute top-0 right-0 p-2 cursor-pointer"
              onClick={handleClose}
            >
              <img src={close} className="h-[30px] m-[10px]" alt="" />
            </div>
            {/* Rest of the content */}
            <div className="absoulte w-full h-full justify-center items-center">
              <SensorInfo siteData={siteData} />
            </div>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default SensorModal;

export const SensorInfo = ({ siteData }) => {


  function formatDateTime(dateTimeString) {


    return moment(dateTimeString).tz('Asia/Riyadh').locale('ar').fromNow();
    // console.log(formattedTime)
    // const dateTime = new Date(dateTimeString);
    // const now = new Date();
    // const diffInMinutes = Math.floor((now - dateTime) / (1000 * 60));
    // console.log(diffInMinutes)

    // if (diffInMinutes < 1) {
    //   return 'Just now';
    // } else if (diffInMinutes < 60) {
    //   return diffInMinutes + ' mins ago';
    // } else if (diffInMinutes < 1440) {
    //   // 1440 minutes = 1 day
    //   const diffInHours = Math.floor(diffInMinutes / 60);
    //   return diffInHours + ' hours ago';
    // } else if (diffInMinutes < 43200) {
    //   // 43200 minutes = 30 days = 1 month (approximately)
    //   const diffInDays = Math.floor(diffInMinutes / 1440);
    //   return diffInDays + ' days ago';
    // } else if (diffInMinutes < 525600) {
    //   // 525600 minutes = 365 days = 1 year (approximately)
    //   const diffInMonths = Math.floor(diffInMinutes / 43200);
    //   return diffInMonths + ' months ago';
    // } else {
    //   const diffInYears = Math.floor(diffInMinutes / 525600);
    //   return diffInYears + ' years ago';
    // }
  }
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortType, setSortType] = useState('name');

  const handleSortClick = (type) => {
    if (sortType === type) {
      // If the same sort type is clicked, reverse the sort order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // If a different sort type is clicked, set it to ascending order
      setSortType(type);
      setSortOrder('asc');
    }
  };




  const tableRowStyles = {
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  };

  // console.log(siteData?.sensor_data[0]?.pk);
  return (
    <>
      {siteData?.sensor_data?.length > 0 ? (
        <Table
          shadow={false}
          aria-label="Example pagination  table"
          css={{
            backgroundColor: 'White',
            height: 'auto',
            minWidth: 'auto',
          }}
          // selectionMode="multiple"
          // selectionMode="multiple"
        >
          <Table.Header>
            {/* <Table.Column>
              <button onClick={() => handleSortClick()}>
                {sortOrder === 'asc' ? 'Sort Descending' : 'Sort Ascending'}
              </button>
            </Table.Column> */}
            <Table.Column css={{ textAlign: 'left', padding: '0 5px' }}>
              آخر تحديث
            </Table.Column>
            <Table.Column css={{ textAlign: 'left', padding: '0 5px' }}>
              <div className="flex gap-1 items-center justify-center">
                <img src={humidity} className="h-[18px]" alt="" />
                الرطوبة
              </div>
            </Table.Column>
            <Table.Column css={{ textAlign: 'left', padding: '0 5px' }}>
              <div className="flex gap-1 items-center justify-center">
                <img src={thermometer} className="h-[20px]" alt="" />
                درجة الحرارة
                <button onClick={() => handleSortClick('temp')}>
                  {sortOrder === 'asc' ? (
                    <AiOutlineCaretDown />
                  ) : (
                    <AiOutlineCaretUp />
                  )}
                </button>
              </div>
            </Table.Column>
            <Table.Column css={{ textAlign: 'right', padding: '0 5px' }}>
              <div className="flex items-center justify-end">
                اسم الحساس{' '}
                <button onClick={() => handleSortClick('name')}>
                  {sortOrder === 'asc' ? (
                    <AiOutlineCaretDown />
                  ) : (
                    <AiOutlineCaretUp />
                  )}
                </button>
              </div>
            </Table.Column>
            <Table.Column css={{ textAlign: 'left', padding: '0 5px' }}>
              الموقع
            </Table.Column>
            <Table.Column css={{ textAlign: 'left', padding: '0 5px' }}>
            حالة
            </Table.Column>
          </Table.Header>
          <Table.Body
            css={{
              alignItems: 'center',
            }}
          >
            {siteData?.sensor_data?.filter(value => value.tempareture > 229 && value.tempareture < 371 && (value.humidity > 0)).slice()

            .sort((a, b) => {

               if (sortType === 'temp'){
                return sortOrder === 'asc' ? a.tempareture - b.tempareture : b.tempareture - a.tempareture;
              }
              else if (sortType === 'name'){
                if (sortOrder === 'asc'){
                  const partsA = a.name.split('-');
                  const partsB = b.name.split('-');

                  const numberA = parseInt(partsA[1]);
                  const numberB = parseInt(partsB[1]);

                  if (partsA[0] !== partsB[0]) {
                    return parseInt(partsA[0]) - parseInt(partsB[0]);
                  }

                  return numberA - numberB;
                }

                else {
                  const partsA = a.name.split('-');
                  const partsB = b.name.split('-');

                  const numberA = parseInt(partsA[1]);
                  const numberB = parseInt(partsB[1]);

                  if (partsA[0] !== partsB[0]) {
                      return parseInt(partsB[0]) - parseInt(partsA[0]);
                  }

                  return numberB - numberA;
                }
              }
            })
              .map((el, i) => (
                <Table.Row key={i} css={tableRowStyles}>
                  <Table.Cell css={{ textAlign: 'left', padding: '0 5px' }}>
                    {el.updated_at
                      ? formatDateTime(el.updated_at)
                      : 'Unavailable'}
                  </Table.Cell>
                  <Table.Cell css={{ textAlign: 'center', padding: '0 5px' }}>
                    {el.humidity}%
                  </Table.Cell>
                  <Table.Cell css={{ textAlign: 'center', padding: '0 5px' }}>
                    {el.tempareture / 10}°C
                  </Table.Cell>
                  <Table.Cell
                    css={{
                      textAlign: 'center',
                      padding: '0 5px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {/* <Tooltip content={`${el.sn}`}> */}
                    <span>{el.name}</span>
                    {/* </Tooltip> */}
                  </Table.Cell>
                  <Table.Cell css={{ textAlign: 'center', padding: '0 5px' }}>
                    {el.location}
                  </Table.Cell>
                  <Table.Cell css={{ textAlign: 'center', padding: '0 5px' }}>
                    {el.online? <div className='w-2 h-2 rounded-full bg-green-500'></div>:<div className='w-2 h-2 rounded-full bg-red-500'></div> }
                  </Table.Cell>
                  {/* <ReactTooltip id="tooltip" place="bottom" getContent={() => 'Hello world! I\'m a Tooltip'} /> */}
                </Table.Row>
              ))}
          </Table.Body>
          <Table.Pagination
            shadow
            noMargin
            align="center"
            rowsPerPage={10}
            onPageChange={page => console.log({ page })}
          />
        </Table>
      ) : (
        <>
        <Table
            shadow={false}
            aria-label="Example pagination  table"
            css={{
              backgroundColor: 'White',
              maxHeight: '0px',
              minWidth: 'auto',
            }}
            // selectionMode="multiple"
            // selectionMode="multiple"
          >
            <Table.Header>
              {/* <Table.Column>
              <button onClick={() => handleSortClick()}>
                {sortOrder === 'asc' ? 'Sort Descending' : 'Sort Ascending'}
              </button>
            </Table.Column> */}
              <Table.Column css={{ textAlign: 'left', padding: '0 5px' }}>
                آخر تحديث
              </Table.Column>
              <Table.Column css={{ textAlign: 'left', padding: '0 5px' }}>
                <div className="flex gap-1 items-center justify-center">
                  <img src={humidity} className="h-[18px]" alt="" />
                  الرطوبة
                </div>
              </Table.Column>
              <Table.Column css={{ textAlign: 'left', padding: '0 5px' }}>
                <div className="flex gap-1 items-center justify-center">
                  <img src={thermometer} className="h-[20px]" alt="" />
                  درجة الحرارة
                </div>
              </Table.Column>
              <Table.Column css={{ textAlign: 'right', padding: '0 5px' }}>
                <div className="flex items-center justify-end">
                  اسم الحساس{' '}
                  <button onClick={() => handleSortClick()}>
                    {sortOrder === 'asc' ? (
                      <AiOutlineCaretDown />
                    ) : (
                      <AiOutlineCaretUp />
                    )}
                  </button>
                </div>
              </Table.Column>
              <Table.Column css={{ textAlign: 'left', padding: '0 5px' }}>
              الموقع
            </Table.Column>
            </Table.Header>
            <Table.Body>
              <Table.Row css={tableRowStyles}>
                <Table.Cell
                  css={{ textAlign: 'left', padding: '0 5px' }}
                ></Table.Cell>
                <Table.Cell css={{ textAlign: 'center', padding: '0 5px' }}>

                </Table.Cell>
                <Table.Cell
                  css={{ textAlign: 'center', padding: '0 5px' }}
                ></Table.Cell>
                <Table.Cell
                  css={{
                    textAlign: 'center',
                    padding: '0 5px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  {/* <Tooltip content={`${el.sn}`}> */}

                  {/* </Tooltip> */}
                </Table.Cell>
                <Table.Cell css={{ textAlign: 'center', padding: '0 5px' }}>
                    {/* {el.location} */}
                  </Table.Cell>
                {/* <ReactTooltip id="tooltip" place="bottom" getContent={() => 'Hello world! I\'m a Tooltip'} /> */}
              </Table.Row>
            </Table.Body>
            <Table.Pagination
              shadow
              noMargin
              align="center"
              rowsPerPage={10}
              onPageChange={page => console.log({ page })}
            />
          </Table>
          {/* <div className="flex justify-center items-center text-3xl">
            <Loading />
          </div> */}
        </>
      )}
    </>
  );
};
