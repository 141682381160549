import { Navigate, Outlet } from 'react-router-dom';
import { getCookie } from '../lib/Cookies';
// import { useAuth } from '../server/auth.context';
// import { useServer } from '../server/server.context';
// import { getCookie } from '../lib/cookie';

export default function PrivateRoute() {
  // const { currentUser } = useAuth();
  // const { GetMyDetails } = useServer();
  // const { loading, data } = GetMyDetails();
  // const user = JSON.parse(getCookie('user'));
  // const permission = user?.data?.sensor_update_permission
  // console.log('cookies user', user?.data?.sensor_update_permission);

  // const sensor_update_permission = getCookie('user')
  // ? JSON.parse(getCookie('user'))?.data?.sensor_update_permission
  // : false;

  // const { currentUser } = useAuth();

if(!getCookie('user')){
    return <Navigate to="/" />;
}
// else if (sensor_update_permission) {
//   console.log("sensor_update_permission ",sensor_update_permission)
//   return <Navigate to="/airquality" />;
//  } else if (getCookie('user'))
// {
//   console.log("getCookie('user') ",getCookie('user'))
//   return <Navigate to="/home" />;
// } else return <Navigate to="/" />;
  // console.log(JSON.parse(getCookie('user')));
  // if (!currentUser.emailVerified) {
  //   alert('Verify your email first!');
  //   return <Navigate to="/signin" />;
  // }

  return <Outlet />;
}
