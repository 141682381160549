import React, { useState } from 'react';
import { HiCamera, HiDocumentReport, HiHome } from 'react-icons/hi';
import { GiMovementSensor } from 'react-icons/gi';
import { Link, NavLink } from 'react-router-dom';
import { FaMapMarkedAlt, FaWeightHanging } from 'react-icons/fa';
import sensorIcon from '../../assets/icons/water-temperature2.png';
import kaabaIcon from '../../assets/icons/kaaba.png';
import weightIcon from '../../assets/icons/weight-scale.png';
import { useEffect } from 'react';

const Sidebar = () => {
  const [isOpen, setOpen] = useState(true);

  const handlesidebar = () => {
    if (window.innerWidth < 640) {
      setOpen(false);
    }
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 640) {
        setOpen(false);
      }
    }

    // Initial check when the component mounts
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className={`text-right text-xl w-full ${
        isOpen ? 'w-screen sm:w-full' : ''
      }`}
    >
      <button
        onClick={() => {
          setOpen(!isOpen);
        }}
        data-drawer-target="separator-sidebar"
        data-drawer-toggle="separator-sidebar"
        aria-controls="separator-sidebar"
        type="button"
        className="inline-flex items-center p-2 my-2 mr-3 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
      >
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>
      <aside id="separator-sidebar" className={``} aria-label="Sidebar">
        <div className="h-screen px-3 py-4 overflow-y-auto bg-[#F7F7F7]">
          <ul className="space-y-2 font-medium side-nav">
            <li>
              <NavLink
                onClick={() => {
                  handlesidebar();
                }}
                className={`${
                  isOpen ? 'sm:pl-10 justify-end' : ' justify-center'
                } flex items-center gap-2 p-2 text-gray-900 rounded-lg hover:bg-gray-100`}
                to="/home"
              >
                {' '}
                <p className={`${isOpen ? 'block' : 'hidden'} transition-all`}>
                  {' '}
                  الصفحة الرئيسية
                </p>
                <HiHome className="" />
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => {
                  handlesidebar();
                }}
                className={`${
                  isOpen ? 'sm:pl-10 justify-end' : ' justify-center'
                } flex items-center gap-2 p-2 text-gray-900 rounded-lg hover:bg-gray-100`}
                to="/sensor-map"
              >
                {' '}
                <p className={`${isOpen ? 'block' : 'hidden'} transition-all`}>
                  {' '}
                  خريطة درجة الحرارة
                </p>
                <FaMapMarkedAlt />
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => {
                  handlesidebar();
                }}
                className={`${
                  isOpen ? 'sm:pl-10 justify-end' : ' justify-center'
                } flex items-center gap-2 p-2 text-gray-900 rounded-lg hover:bg-gray-100`}
                to="/cameras"
              >
                <p className={`${isOpen ? 'block' : 'hidden'} transition-all`}>
                  حصر اعداد الحجاج
                </p>

                <HiCamera className="" />
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => {
                  handlesidebar();
                }}
                className={`${
                  isOpen ? 'sm:pl-10 justify-end' : ' justify-center'
                } flex items-center gap-2 p-2 text-gray-900 rounded-lg hover:bg-gray-100`}
                to="/sensor"
              >
                <p className={`${isOpen ? 'block' : 'hidden'} transition-all`}>
                  المؤشرات البيئية
                </p>
                <img width={15} src={sensorIcon} alt="" />
              </NavLink>
            </li>
            <li>
              <Link
                onClick={() => {
                  handlesidebar();
                }}
                className={`${
                  isOpen ? 'sm:pl-10 justify-end' : ' justify-center'
                } flex items-center gap-2 p-2 text-gray-900 rounded-lg hover:bg-gray-100`}
                // to="/weights"
              >
                <p className={`${isOpen ? 'block' : 'hidden'} transition-all`}>
                  وزن الوجبه
                </p>
                <img width={18} src={weightIcon} alt="" />
              </Link>
            </li>
            <li>
              <NavLink
                onClick={() => {
                  handlesidebar();
                }}
                className={`${
                  isOpen ? 'sm:pl-10 justify-end' : ' justify-center'
                } flex items-center gap-2 p-2 text-gray-900 rounded-lg hover:bg-gray-100`}
                to="/arafa"
              >
                <p className={`${isOpen ? 'block' : 'hidden'} transition-all`}>
                  عرفة
                </p>
                <img width={15} src={sensorIcon} alt="" />

                {/* <HiDocumentReport /> */}
              </NavLink>
            </li>
            {/* <li>
              <NavLink
              onClick={() => {handlesidebar()}}
                className={`${isOpen ? 'sm:pl-10 justify-end' : ' justify-center'} flex items-center gap-2 p-2 text-gray-900 rounded-lg hover:bg-gray-100`}
                to="/"
              >
                <p className={`${isOpen ? 'block': 'hidden' } transition-all`}>التقارير</p>

                <HiDocumentReport />
              </NavLink>
            </li> */}
          </ul>
        </div>
      </aside>

      {/* <ul className="grid gap-5 side-nav fixed">
        <li>
          <NavLink className="flex justify-end items-center" to="/home">
            {' '}
            <p className='hidden sm:block'> الصفحة الرئيسية</p>
            <p className="px-2"></p>
            <HiHome className="" />
          </NavLink>
        </li>
        <li>
          <NavLink className="flex justify-end items-center" to="/cameras">
            <p className='hidden sm:block'>حصر اعداد الحجاج</p>
            <p className="px-2"></p>
            <HiCamera className="" />
          </NavLink>
        </li>
        <li>
          <NavLink className="flex justify-end items-center" to="/sensor">
            <p className='hidden sm:block'>المؤشرات البيئية</p>
            <p className="px-2"></p>
            <GiMovementSensor className="" />
          </NavLink>
        </li>
        <li>
          <NavLink className="flex justify-end items-center" to="/weights">
            <p className='hidden sm:block'>وزن الوجبه</p>
            <p className="px-2"></p>
            <FaWeightHanging />
          </NavLink>
        </li>
        <li>
          <NavLink className="flex justify-end items-center" to="/">
            <p className='hidden sm:hidden'>عرفة</p>
            <p className="px-2"></p>
            <HiDocumentReport />
          </NavLink>
        </li>
        <li>
          <NavLink className="flex justify-end items-center" to="/">
            <p className='hidden sm:block'>التقارير</p>
            <p className="px-2"></p>
            <HiDocumentReport />
          </NavLink>
        </li>
      </ul> */}
    </div>
  );
};

export default Sidebar;
