import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Card } from '@nextui-org/react';
import {
  Box as MBox,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
} from '@mui/material';

import {
  FaArrowLeft,
  FaArrowRight,
  FaTemperatureLow,
  FaChevronCircleLeft,
  FaChevronLeft,
  FaUsers,
} from 'react-icons/fa';
import SiteMarkedMap from './SiteMarkedMap';
import CountUp from 'react-countup';
import { Loading, Table, Tooltip } from '@nextui-org/react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import bus from '../assets/icons/bus.png';
import portalEnter from '../assets/icons/portal-enter.png';
import portalExit from '../assets/icons/portal-exit.png';
import air from '../assets/icons/air-quality-sensor.gif';
import thermometer from '../assets/icons/temperature.gif';
import humidity from '../assets/icons/humidity.gif';
import scale from '../assets/icons/scale.png';
import capacity from '../assets/icons/capacity.png';
import DataTable from './DataTable';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import SensorModal, { SensorInfo } from './SensorModal';
import OrderCard from './OrderCard2';
import WeightModal from './WeightModal3';
import { useSelector, useDispatch } from 'react-redux';
import {
  setMaxHumadity,
  setMaximumTemp,
  setMinHumadity,
  setMinimumTemp,
  setSite,
} from '../redux/global';
import { GrGroup } from 'react-icons/gr';
import getEnvironmentData from '../util/getEnvironment';
import formatDateTime from '../util/timeDate';
import { useGetTentListQuery } from '../redux/api';
import ImageMarker from 'react-image-marker';
import CustomMarker from './customMarker';



const SensorMapLocation = ({ tentList }) => {
    const dispatch = useDispatch();
  const site = useSelector(state => state?.global?.site) || {};
  const TentList = useSelector(state => state?.global?.TentList);
  const arafa = useSelector(state => state?.global?.Arafa) || [];
  const arafaPK = arafa?.map(tent => tent?.pk);
  const notArafa = TentList?.filter(tent => arafaPK.indexOf(tent?.pk) === -1);
  const { data: tentData } = useGetTentListQuery(undefined, {
    pollingInterval: 60000,
  });
  // console.log(notArafa);
  const siteData =
    useSelector(
      state => state?.global?.TentData?.filter(tent => tent?.pk === site?.pk)[0]
    ) || {};


  const orderList = useSelector(state => state?.global?.OrderList) || [];
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenWeight, setIsOpenWeight] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleCloseWeight = () => {
    setIsOpenWeight(false);
  };


  let environment = undefined;
  let n = undefined;
  if (siteData?.sensor_data && siteData?.sensor_data?.length > 0) {
    environment = getEnvironmentData(siteData?.sensor_data);
    n = siteData?.sensor_data?.length;
  }
  const airData = tentData?.filter(tent => tent?.pk === site?.pk)[0];
  // console.log('site info', site, tentData, airData?.air_condition);
  const [day, setDay] = useState('');
  const setDate = () => {
    const now = new Date();
    const isoCurrentDate = now.toLocaleDateString('sv', {
      timeZone: 'Asia/Riyadh',
    });
    setDay(isoCurrentDate);
  };
  let totals_in = 0;
  let totals_out = 0;

  const siteData2 = tentList?.filter(i => i.pk === site?.pk)[0];
  totals_in = siteData2?.realtime_data?.totals_in;
  totals_out = siteData2?.realtime_data?.totals_out;

  const staying = totals_in - totals_out > 0 ? totals_in - totals_out : 0;
  const available =
    staying >= 0 ? (siteData2?.capacity - staying) : siteData2?.capacity;
  const sign = available > 0 ? '' : '+';

  const [markerState, setMarkerState] = useState(siteData?.sensor_data || [])
    // useEffect hook to update markerState when siteData changes
    useEffect(() => {
        // Check if siteData.sensor_data exists before setting markerState
        if (siteData.sensor_data) {
          setMarkerState(siteData.sensor_data);
        } else {
          setMarkerState([]); // Handle case when sensor_data is not defined or falsy
        }
      }, [siteData]);
  const baseurl = "https://baitguests.com:8000"

  const [markers, setMarkers] = useState();




    return (
        <>
        {isOpenWeight && (
          <WeightModal
            tent={tentList?.filter(i => i.pk === site?.pk)[0]}
            orderList={orderList}
            day={day}
            isOpenWeight={isOpenWeight}
            handleCloseWeight={handleCloseWeight}
          />
        )}
        {isOpen && (
          <SensorModal
            siteData={siteData}
            isOpen={isOpen}
            handleClose={handleClose}
            id={siteData?.pk}
            setIsOpen={setIsOpen}
          />
        )}
        <div className="w-full">
          <h1 className="w-full mb-10 sm:mb-0 text-center text-4xl pt-4 text-[#d68e35]">
            الصفحة الرئيسية
          </h1>
        </div>
        <div className="mb-5 grid place-content-end">
          <MBox sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">المركز</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={JSON.stringify(site)}
                label="sites"
                defaultValue=""
                onChange={e => {
                  // console.log(e.target.value);
                  dispatch(setSite(JSON.parse(e.target.value)));
                  localStorage.setItem('camera', e.target.value);
                }}
                renderValue={site =>
                  `${JSON.parse(site)?.name} ${JSON.parse(site)?.location}`
                }
              >
                {/* <MenuItem
                  value={JSON.stringify(site)}
                  className="py-5 px-4 bg-red-200"
                >
                  {site?.name} {site?.location}
                </MenuItem> */}
                {tentList
                  .filter(tent => !tent?.is_arafa)
                  .map((el, i) => {
                    return (
                      <MenuItem
                        value={JSON.stringify(el)}
                        key={Math.random()}
                        sx={{ py: 2 }}
                      >
                        {el.name} مركز
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </MBox>
        </div>
        <div className="justify-between gap-8">
          {/* left side */}
          <div className="basis-full lg:basis-7/12 flex flex-col gap-8 mb-5">
            <Card
              className="col-span-2"
              isPressable
              isHoverable
              variant="bordered"
              css={{ mw: 'auto' }}
            >
              <div
                className="grid grid-cols-3 gap-5 p-5"
                onClick={() => setIsOpen(true)}
              >
                <p className="col-span-3 text-blue-800 text-right text-2xl">
                  المؤشرات البيئية
                </p>
                <Card
                  // className="col-span-2"
                  isPressable
                  isHoverable
                  variant="bordered"
                  css={{ mw: 'auto', p: '$5' }}
                >
                  <div className="flex gap-1 items-center justify-end">
                    <p className="text-xl text-blue-800">جودة الهواء</p>
                    <img src={air} className="h-[30px] m-[5px]" alt="" />
                  </div>

                  <div className="flex gap-1 items-end justify-center">
                    <h2 className="text-xl font-bold text-center">
                      {airData?.air_condition === null ? (
                        <h1 className="text-2xl font-bold">_</h1>
                      ) : airData?.air_condition === 0 ? (
                        'سيء' //'Bad'
                      ) : airData?.air_condition === 1 ? (
                        'متوسط' //'Medium'
                      ) : (
                        'جيد' //'Good'
                      )}
                    </h2>
                  </div>
                </Card>

                <Card
                  // className="col-span-2"
                  isPressable
                  isHoverable
                  variant="bordered"
                  css={{ mw: 'auto', p: '$5' }}
                >
                  {environment ? (
                    <div className="w-full  justify-between items-end rounded-lg">
                      <div className="flex justify-end items-center gap-2 grid-cols-2 pb-2">
                        <p className="text-xl text-blue-800"> الرطوبة</p>
                        <img src={humidity} className="h-[30px] m-[2px]" alt="" />
                      </div>
                      <div className="flex gap-1 items-end justify-center">
                        <h2 className="text-3xl font-bold p-[5px]">
                          {environment ? (
                            <>
                              <CountUp
                                start={0}
                                end={environment?.totalHumidity / environment?.totalSensor}
                                duration={2}
                                separator=","
                              />
                              <span>%</span>
                            </>
                          ) : (
                            '_'
                          )}
                        </h2>
                      </div>
                      <div className="flex justify-between">
                        <p className="text-green-600">
                          <span>
                            {environment
                              ? `${environment?.minHumadity.toPrecision(3)} %`
                              : '.'}
                          </span>
                        </p>
                        <p className="text-orange-600">
                          <span>
                            {environment
                              ? `${environment?.maxHumadity.toPrecision(3)} %`
                              : '.'}
                          </span>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="w-full  justify-between items-end rounded-lg">
                      <div className="flex justify-end items-center gap-2 grid-cols-2 pb-2">
                        <p className="text-xl text-blue-800"> الرطوبة</p>
                        <img src={humidity} className="h-[30px] m-[2px]" alt="" />
                      </div>
                      <div className="w-full flex flex-col items-center">
                        <h1 className="text-2xl font-bold">_</h1>
                      </div>
                    </div>
                  )}
                </Card>

                <Card
                  // className="col-span-2"
                  isPressable
                  isHoverable
                  variant="bordered"
                  css={{ mw: 'auto', mh: '250px', p: '$5' }}
                >
                  {environment ? (
                    <div className="w-full  justify-between  items-end rounded-lg">
                      <div className="flex gap-1 items-center justify-end">
                        <p className="text-xl text-blue-800"> الحرارة</p>
                        <img
                          src={thermometer}
                          className="h-[30px] m-[5px]"
                          alt=""
                        />
                      </div>

                      <div className="h-auto flex gap-1 items-end justify-center">
                        <h2 className="text-3xl p-[5px] font-bold items-center justify-center">
                          {environment ? (
                            <>
                              <CountUp
                                start={0}
                                end={environment?.totalTemp / environment?.totalSensor}
                                duration={2}
                                separator=","
                              />
                              <span>°C</span>
                            </>
                          ) : (
                            '_'
                          )}
                        </h2>
                      </div>
                      <div className="flex justify-between">
                        <p className="text-green-600">
                          <span>
                            {environment?.totalTemp / environment?.totalSensor > 0
                              ? `${environment?.minimumTemp?.toPrecision(3)} °C`
                              : '.'}
                          </span>
                        </p>
                        <p className="text-orange-600">
                          <span>
                            {environment?.totalTemp / environment?.totalSensor > 0
                              ? `${environment?.maximumTemp.toPrecision(3)} °C`
                              : '.'}
                          </span>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="w-full  justify-between items-end rounded-lg">
                      <div className="flex gap-1 items-center justify-end">
                        <p className="text-xl text-blue-800"> الحرارة</p>
                        <img
                          src={thermometer}
                          className="h-[30px] m-[5px]"
                          alt=""
                        />
                      </div>
                      <div className="w-full flex flex-col items-center">
                        <h1 className="text-2xl font-bold">_</h1>
                      </div>
                    </div>
                  )}
                </Card>
              </div>
            </Card>
            {/* map */}
            {/* <div className="bg-white p-2 rounded-lg border border-gray-300 hover:shadow-xl">
              <SiteMarkedMap tentList={notArafa} selectedTentId={site?.pk} />
            </div> */}

            <div className='w-full'>
                {/* <img className='w-full'  alt="" /> */}
                <div className='marker_container'>
                { site?.map_image !== "/media/0" &&
                    <ImageMarker
                    src={`${baseurl}${site?.map_image}`}
                    alt=''
                    markers={markerState}
                    // onAddMarker={(marker) => addMarker(marker)}
                    markerComponent={CustomMarker}
                    />
                }
                </div>
            </div>

            {/* Sitetable */}
            {/* <DataTable tentList={tentList} /> */}
          </div>
          {/* right side */}

        </div>
      </>
    );
};

export default SensorMapLocation;