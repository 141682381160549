/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { orderListAPI, tentDataAPI } from '../lib/apis';
import WeightModal from './WeightModal';

const OrderCard = ({ tent, orderList, day, isTitleShow = true, fontSize = '18px'}) => {

  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
 
  let dateWiseOrder = orderList
    ?.filter(order => order.deviceNum === parseInt(tent?.name)) // .split(' ')[1]))
    .filter(dworder => dworder.date.slice(0, 10) === day);
  // console.log('datawiseorder', dateWiseOrder, orderList);

  let breakfast = dateWiseOrder?.filter(
    breakfast =>
      '02:00:00' <= breakfast.date.slice(11) &&
      '10:00:00' >= breakfast.date.slice(11)
  );
  let launch = dateWiseOrder?.filter(
    launch =>
      '12:00:00' <= launch.date.slice(11) && '15:00:00' >= launch.date.slice(11)
  );
  let dinner = dateWiseOrder?.filter(
    dinner =>
      '18:00:00' <= dinner.date.slice(11) && '23:59:00' >= dinner.date.slice(11)
  );


  let bAccepted = breakfast?.filter(order => order.weight >= 300);
  let lAccepted = launch?.filter(order => order.weight >= 400);
  let dAccepted = dinner?.filter(order => order.weight >= 400);

  // console.log(bAccepted);

  const bTotalWt = bAccepted?.reduce((sum, order) => sum + order.weight, 0);
  const lTotalWt = lAccepted?.reduce((sum, order) => sum + order.weight, 0);
  const dTotalWt = dAccepted?.reduce((sum, order) => sum + order.weight, 0);

  const bAvgWt = bAccepted?.length > 0 ? bTotalWt / bAccepted?.length : 0;
  const lAvgWt = lAccepted?.length > 0 ? lTotalWt / lAccepted?.length : 0;
  const dAvgWt = dAccepted?.length > 0 ? dTotalWt / dAccepted?.length : 0;
  var v = 0;
  const totalAccepted =
    bAccepted?.length > 0
      ? bAccepted?.length
      : 0 + lAccepted?.length > 0
      ? lAccepted?.length
      : 0 + dAccepted?.length > 0
      ? dAccepted?.length
      : 0;
  // check bAccepted, lAccepted, dAccepted is not empty then increment the value of v
  if (bAccepted?.length > 0) {
    v++;
  }
  if (lAccepted?.length > 0) {
    v++;
  }
  if (dAccepted?.length > 0) {
    v++;
  }

  const avgWeight = v > 0 ? (bAvgWt + lAvgWt + dAvgWt) / v : 0;
  // console.log('weight', bAvgWt, lAvgWt, dAvgWt, bAccepted, v);

  // const bTotalAccepted = bAccepted?.reduce((sum, order) => sum + order.weight, 0);
  // const lTotalAccepted = lAccepted?.reduce((sum, order) => sum + order.weight, 0);
  // const dTotalAccepted = dAccepted?.reduce((sum, order) => sum + order.weight, 0);

  return (
    <>
      
      {/* <p>{tent.capacity}</p> */}
      <div
        className="bg-white p-3 rounded  cursor-pointer "
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {isTitleShow && <h4 className="text-center mb-3">{tent?.name}: اسم المركز</h4>}
        {/* <div className="flex justify-between items-center">
          <p className="text-xs text-slate-500">Staying</p>
          <p className="text-sm font-semibold">
            <CountUp
              start={0}
              end={
                siteData?.realtime?.staying >= 0
                  ? Math.ceil(siteData?.realtime?.staying)
                  : 0
              }
              duration={2}
              separator=","
            />
          </p>
        </div> */}
        <div className={`text-[${fontSize}]`}>
        <div className="flex flex-row-reverse justify-between items-center ">
          <p className=" text-blue-800 m-[3px]">أعداد الحجاج</p>
          <p className=" font-semibold text-black m-[3px]">
            <CountUp
              start={0}
              end={tent?.capacity ? tent?.capacity : 'Unavailable'}
              duration={2}
              separator=","
            />
            {/* {tent?.capacity} */}
          </p>
        </div>
        <div className="mb-5">
          <p className="flex flex-row-reverse justify-between  m-[3px]">
            <span className="text-blue-800">عدد الوجبات</span>
            <span className="text-black">
              <CountUp
                start={0}
                end={totalAccepted}
                duration={2}
                separator=","
              />
            </span>
          </p>
          <p className=" flex justify-between flex-row-reverse  m-[3px]">
            <span className="text-blue-800">متوسط الوزن</span>
            <span className="text-black">
              <CountUp start={0} end={avgWeight} duration={2} separator="," />
            </span>
          </p>
        </div>
        </div>
      </div>
    </>
  );
};

export default OrderCard;

// {
/* <div className="bg-white p-3 rounded">
  <h4 className="text-center mb-3">{tent.name} مركز</h4>
  <h4 className="text-center mb-3">{tent.capacity} :العدد الفعلي</h4>
  <table className="w-full table-auto text-center text-sm">
    <thead>
      <tr className="text-center">
        <th style={{ padding: '7px' }}> المجموع </th>
        <th style={{ padding: '7px' }}> الوجبات المرفوضة </th>
        <th style={{ padding: '7px' }}> الوجبات المقبولة </th>
        <th style={{ padding: '7px' }}> الوزن المتوسط </th>
        <th style={{ padding: '7px' }}></th>
      </tr>
    </thead>
    <tbody>
      <tr className="text-center">
        <td className="total">
          <CountUp
            start={0}
            end={breakfast.length >= 0 ? Math.ceil(breakfast.length) : 0}
            duration={2}
            separator=","
          />
        </td>
        <td className="rejected">
          <CountUp
            start={0}
            end={
              breakfast.length - bAccepted.length >= 0
                ? Math.ceil(breakfast.length - bAccepted.length)
                : 0
            }
            duration={2}
            separator=","
          />
        </td>

        <td className="accepted">
          <CountUp
            start={0}
            end={bAccepted.length >= 0 ? Math.ceil(bAccepted.length) : 0}
            duration={2}
            separator=","
          />
        </td>
        <td className="avg_wt">
          <CountUp
            start={0}
            end={bAvgWt >= 0 ? Math.ceil(bAvgWt) : 0}
            duration={2}
            separator=","
          />
          gm
        </td>
        <td>الفطور</td>
      </tr>
      <tr className="text-center">
        <td className="total">
          <CountUp
            start={0}
            end={launch.length >= 0 ? Math.ceil(launch.length) : 0}
            duration={2}
            separator=","
          />
        </td>
        <td className="rejected">
          <CountUp
            start={0}
            end={
              launch.length - lAccepted.length >= 0
                ? Math.ceil(launch.length - lAccepted.length)
                : 0
            }
            duration={2}
            separator=","
          />
        </td>
        <td className="accepted">
          <CountUp
            start={0}
            end={lAccepted.length >= 0 ? Math.ceil(lAccepted.length) : 0}
            duration={2}
            separator=","
          />
        </td>

        <td className="avg_wt">
          <CountUp
            start={0}
            end={lAvgWt >= 0 ? Math.ceil(lAvgWt) : 0}
            duration={2}
            separator=","
          />
          gm
        </td>
        <td>الغداء</td>
      </tr>
      <tr className="text-center">
        <td className="total">
          <CountUp
            start={0}
            end={dinner.length >= 0 ? Math.ceil(dinner.length) : 0}
            duration={2}
            separator=","
          />
        </td>
        <td className="rejected">
          <CountUp
            start={0}
            end={
              dinner.length - dAccepted.length >= 0
                ? Math.ceil(dinner.length - dAccepted.length)
                : 0
            }
            duration={2}
            separator=","
          />
        </td>
        <td className="accepted">
          <CountUp
            start={0}
            end={dAccepted.length >= 0 ? Math.ceil(dAccepted.length) : 0}
            duration={2}
            separator=","
          />
        </td>

        <td className="avg_wt">
          <CountUp
            start={0}
            end={dAvgWt >= 0 ? Math.ceil(dAvgWt) : 0}
            duration={2}
            separator=","
          />
          gm
        </td>
        <td>العشاء</td>
      </tr>
      {/* <hr className='w-full border-2'/> */
// }
//       <tr className="text-center mt-2 pt-2 border-t-2 border-black">
//         <td className="total">
//           <CountUp
//             start={0}
//             end={
//               dateWiseOrder.length >= 0 ? Math.ceil(dateWiseOrder.length) : 0
//             }
//             duration={2}
//             separator=","
//           />
//         </td>

//         <td className="rejected">
//           <CountUp
//             start={0}
//             end={
//               dateWiseOrder.length - totalAccepted >= 0
//                 ? Math.ceil(dateWiseOrder.length - totalAccepted)
//                 : 0
//             }
//             duration={2}
//             separator=","
//           />{' '}
//         </td>
//         <td className="accepted">
//           <CountUp
//             start={0}
//             end={totalAccepted >= 0 ? Math.ceil(totalAccepted) : 0}
//             duration={2}
//             separator=","
//           />
//         </td>

//         <td className="avg_wt"> </td>

//         <td>المجموع</td>
//       </tr>
//     </tbody>
//   </table>
// </div>; */}
