import React, { useState } from 'react';
import CountUp from 'react-countup';
import SensorModal from './SensorModal';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { Card } from '@nextui-org/react';
import air from '../assets/icons/air-quality-sensor.gif';
import thermometer from '../assets/icons/temperature.gif';
import humidity from '../assets/icons/humidity.gif';
import formatDateTime from '../util/timeDate';
import { useGetTentListQuery } from '../redux/api';

const SensorCard = ({ siteData, is_arafa = false }) => {
  const site = useSelector(state => state?.global?.site) || {};
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const n = siteData?.sensor_data?.length;
  let sortedUpdateTime = undefined;
  if (siteData?.sensor_data && siteData?.sensor_data?.length > 0) {
    sortedUpdateTime = siteData?.sensor_data
      ?.map(data => data?.updated_at)
      ?.sort((a, b) => {
        return new Date(b) - new Date(a);
      })[0];
  }

  const { data: tentData } = useGetTentListQuery(undefined, {
    pollingInterval: 60000,
  });
  const airData = tentData?.filter(tent => tent?.pk === siteData?.pk)[0];
  console.log("site test0", siteData)

  return (
    <>
      {isOpen && (
        <SensorModal
          siteData={siteData}
          isOpen={isOpen}
          handleClose={handleClose}
          id={siteData?.pk}
          setIsOpen={setIsOpen}
        />
      )}
      <Card
        // className="col-span-2"
        isPressable
        isHoverable
        variant="bordered"
        css={{
          mw: '350px',
          bg: siteData?.isRed
            ? '#fff1cc '
            : siteData?.isGreen
            ? '#dfd'
            : '#ffffff',
        }}
        className=" text-[70%]"
      >
        {/* md:basis-[calc(50%-0.75rem)] #f3fff5 lg:basis-[calc(33.33%-0.75rem)] xl:basis-[calc(25%-0.75rem)] xxl:basis-[calc(20%-0.75rem)] */}
        <div
          className="grid grid-cols-3 gap-5 p-5"
          onClick={() => setIsOpen(true)}
        >
          <p className={`col-span-3 text-right text-2xl text-blue-800`}>
            {siteData?.name} {is_arafa ? '' : 'مركز'}
          </p>
          <Card
            // className="col-span-2"
            isPressable
            isHoverable
            variant="bordered"
            css={{ mw: 'auto', p: '5px' }}
          >
            <div className="flex gap-1 items-center justify-start">
              <p className="text-xs text-blue-800">جودة الهواء</p>
              <img src={air} className="h-[30px] m-[5px]" alt="" />
            </div>

            <div className="flex gap-1 items-end justify-center">
              <h2 className="text-xl font-bold text-center">
                {airData?.air_condition === null ? (
                  <h1 className="text-2xl font-bold">_</h1>
                ) : airData?.air_condition === 0 ? (
                  'سيء' //'Bad'
                ) : airData?.air_condition === 1 ? (
                  'متوسط' //'Medium'
                ) : (
                  'جيد' //'Good'
                )}
              </h2>
            </div>
          </Card>

          <Card
            // className="col-span-2"
            isPressable
            isHoverable
            variant="bordered"
            css={{ mw: 'auto', p: '3px' }}
          >
            {siteData?.environment ? (
              <div className="w-full  justify-between items-end rounded-lg">
                <div className="flex justify-end items-center gap-1 grid-cols-2 pb-2">
                  <p className="text-xs text-blue-800"> الرطوبة</p>
                  <img src={humidity} className="h-[24px] m-[5px]" alt="" />
                </div>
                <div className="flex gap-1 items-end justify-center">
                  <h2 className="text-xl font-bold">
                    {siteData?.environment ? (
                      <>
                        <CountUp
                          start={0}
                          end={siteData?.environment?.totalHumidity / siteData?.environment?.totalSensor}
                          duration={2}
                          separator=","
                        />
                        <span>%</span>
                      </>
                    ) : (
                      '-'
                    )}
                  </h2>
                </div>
                <div className="flex justify-between">
                  <p className="text-green-600">
                    <span>
                      {siteData?.environment.totalHumidity / siteData?.environment?.totalSensor > 0
                        ? `${siteData?.environment?.minHumadity.toPrecision(
                            3
                          )}%`
                        : '-'}
                    </span>
                  </p>
                  <p className="text-orange-600">
                    <span>
                      {siteData?.environment.totalHumidity / siteData?.environment?.totalSensor > 0
                        ? `${siteData?.environment?.maxHumadity.toPrecision(
                            3
                          )}%`
                        : '-'}
                    </span>
                  </p>
                </div>
              </div>
            ) : (
              <div className="w-full  justify-between items-end rounded-lg">
                <div className="flex justify-end items-center gap-1 grid-cols-2 pb-2">
                  <p className="text-xs text-blue-800"> الرطوبة</p>
                  <img src={humidity} className="h-[30px] m-[5px]" alt="" />
                </div>
                <div className="w-full flex flex-col items-center">
                  <h1 className="text-2xl font-bold">_</h1>
                </div>
              </div>
            )}
          </Card>

          <Card
            // className="col-span-2"
            isPressable
            isHoverable
            variant="bordered"
            css={{ mw: 'auto', p: '5px' }}
          >
            {siteData?.environment ? (
              <div className="w-full  justify-between items-end rounded-lg">
                <div className="flex gap-1 items-center">
                  <p className="text-xs text-blue-800"> الحرارة</p>
                  <img src={thermometer} className="h-[30px] m-[5px]" alt="" />
                </div>

                <div className="h-auto flex gap-1 items-end justify-center">
                  <h2 className="text-xl font-bold items-center justify-center">
                    {siteData?.environment ? (
                      <>
                        <CountUp
                          start={0}
                          end={siteData?.environment?.totalTemp / siteData?.environment?.totalSensor > 0 ? siteData?.environment?.totalTemp / siteData?.environment?.totalSensor : "_" }
                          duration={2}
                          separator=","
                        />
                        <span>°C</span>
                      </>
                    ) : (
                      '-'
                    )}
                  </h2>
                </div>
                <div className="flex gap-2">
                  <p className="text-green-600">
                    <span>
                      {siteData?.environment.totalTemp / siteData?.environment?.totalSensor > 0
                        ? `${siteData?.environment?.minimumTemp?.toPrecision(
                            3
                          )}°C`
                        : '-'}
                    </span>
                  </p>
                  <p className="text-orange-600">
                    <span>
                      {siteData?.environment.totalTemp / siteData?.environment?.totalSensor > 0
                        ? `${siteData?.environment?.maximumTemp.toPrecision(
                            3
                          )}°C`
                        : '-'}
                    </span>
                  </p>
                </div>
              </div>
            ) : (
              <div className="w-full  justify-between  items-end rounded-lg">
                <div className="flex gap-1 items-center justify-start">
                  <p className="text-xs text-blue-800"> الحرارة</p>
                  <img src={thermometer} className="h-[30px] m-[5px]" alt="" />
                </div>
                <div className="w-full flex flex-col items-center">
                  <h1 className="text-2xl font-bold">_</h1>
                </div>
              </div>
            )}
          </Card>
        </div>
        <div className="w-full px-6 pb-2 text-slate-400 flex flex-row justify-end">
          <Typography sx={{ fontSize: 10 }}>
            آخر تحديث:{' '}
            {sortedUpdateTime ? formatDateTime(sortedUpdateTime) : 'غير متوفره'}
          </Typography>
        </div>
      </Card>
    </>
  );
};

export default SensorCard;
