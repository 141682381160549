export default function getEnvironmentData(data) {
const filtered = data?.filter(value => value?.tempareture > 229 && value?.tempareture < 371 && value?.humidity > 0 && value?.location.toLowerCase() !== "kitchen");
const lengthOfArray = filtered ? filtered.length : 0;

  return filtered?.reduce(
    (temp, data) => {
      return {
        maximumTemp: Math.max(temp.maximumTemp, data.tempareture / 10),
        minimumTemp: Math.min(temp.minimumTemp, data.tempareture / 10),
        maxHumadity: Math.max(temp.maxHumadity, data.humidity),
        minHumadity: Math.min(temp.minHumadity, data.humidity),
        totalTemp: temp.totalTemp + data.tempareture / 10,
        totalHumidity: temp.totalHumidity + data.humidity,
        totalSensor: lengthOfArray,
      };
    },
    {
      maximumTemp: -1000000000000,
      minimumTemp: 1000000000000,
      maxHumadity: -1000000000000,
      minHumadity: 1000000000000,
      totalTemp: 0,
      totalHumidity: 0,
      totalSensor: 0,
    }
  );
}


// .filter((item) => (item.temperature / 10) > 0 && (item.humidity / 10) > 0)