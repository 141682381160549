import React, { useEffect, useState } from 'react';
import AdminLayout from '../Layouts/AdminLayout';
import {
  Button,
  Flex,
  FormControl,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';

import { Input, Checkbox } from '@nextui-org/react';
import { RegisterUserAPI, tentListAPI } from '../../lib/apis';

export default function UserRegistration() {
  const [tentList, setTentList] = useState([]);
  // const [selectedTents, setSelectedTents] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [userRegData, setUserRegData] = useState({
    username: '',
    email: '',
    password: '',
    password2: '',
    tent_list: '',
  });

  // Fetch tent list on component mount
  useEffect(() => {
    const fetchData = async () => {
      await tentListAPI(setTentList);
    };
    fetchData();
  }, []);

  const [selectedTents, setSelectedTents] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleTentSelection = tentPk => {
    if (selectedTents.includes(tentPk)) {
      setSelectedTents(selectedTents.filter(pk => pk !== tentPk));
    } else {
      setSelectedTents([...selectedTents, tentPk]);
    }
  };

  const onSelectAllChange = () => {
    setSelectAll(!selectAll);
    setSelectedTents(selectAll ? [] : tentList.map(tent => tent.pk));
  };

  // console.log('tentlist', selectedTents);

  // Handle form submission
  const handleSubmit = () => {
    const selectedTentIds = selectedTents.join(',');
    // console.log({ ...userRegData, tent_list: selectedTentIds });

    if (!userRegData.username) {
      alert('put the username');
    } else if (!userRegData.email) {
      alert('put the email');
    } else if (!userRegData.password) {
      alert('put the password');
    } else if (!userRegData.password2) {
      alert('put the confirm password');
    } else if (userRegData.password2 !== userRegData.password) {
      alert('passwords doesnt match');
    } else {
      setSubmitLoading(true);
      RegisterUserAPI(
        { ...userRegData, tent_list: selectedTentIds },
        setUserRegData,
        setSubmitLoading
      );
    }
  };

  return (
    <AdminLayout>
      <Flex w="full" h="full" justifyContent={'center'} mt={20}>
        <VStack spacing={4} w={'40%'}>
          <HStack justifyContent={'space-between'} w="100%">
            <Text w="30%">Username</Text>
            <Input
              type="text"
              placeholder="Enter username"
              value={userRegData.username}
              onChange={e => {
                setUserRegData({ ...userRegData, username: e.target.value });
              }}
            />
          </HStack>
          <HStack justifyContent={'space-between'} w="100%">
            <Text w="30%">Email</Text>
            <Input
              type="email"
              placeholder="Enter email"
              value={userRegData.email}
              onChange={e => {
                setUserRegData({ ...userRegData, email: e.target.value });
              }}
            />
          </HStack>
          <HStack justifyContent={'space-between'} w="100%">
            <Text w="30%">Password</Text>
            <Input
              type="password"
              placeholder="Enter password"
              value={userRegData.password}
              onChange={e => {
                setUserRegData({ ...userRegData, password: e.target.value });
              }}
            />
          </HStack>
          <HStack justifyContent={'space-between'} w="100%">
            <Text w="30%">Corfirm Password</Text>
            <Input
              type="password"
              placeholder="Re-type the password"
              value={userRegData.password2}
              onChange={e => {
                setUserRegData({ ...userRegData, password2: e.target.value });
              }}
            />
          </HStack>
          <HStack justifyContent={'space-between'} w="100%">
            <Text w="30%">Select Sites</Text>
            <Flex
              flexFlow={'column'}
              h={40}
              p={5}
              className="scrolling"
              pr={20}
              border="1px solid"
              borderColor={'ash.200'}
              rounded={10}
            >
              <Checkbox
                checked={selectAll}
                onChange={onSelectAllChange}
                size="md"
              >
                Select All
              </Checkbox>
              {tentList.map(tent => (
                <Checkbox
                  key={tent.pk}
                  checked={selectedTents.includes(tent.pk)}
                  onChange={() => handleTentSelection(tent.pk)}
                  size="md"
                >
                  {tent.name}
                </Checkbox>
              ))}
              {/* <HStack>
                <Checkbox onChange={handleChange} />
                <Text>Select All</Text>
              </HStack> */}
              {/* {tentList.map(tent => (
                <FormControl key={tent.pk}>
                  <HStack> */}
              {/* check that if tents are selected or not, if selected than all the checkbox will be checked or else same as below */}

              {/* <Checkbox
                      isChecked={selectedTents?.includes(tent.pk)}
                      checked={selectedTents?.includes(tent.pk)}
                      onChange={() => handleTentSelection(tent.pk)}
                    />
                    <Text>{tent.name}</Text>
                  </HStack>
                </FormControl>
              ))} */}
            </Flex>
          </HStack>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isLoading={submitLoading}
          >
            Submit
          </Button>
        </VStack>
      </Flex>
    </AdminLayout>
  );
}
