import React from 'react';
import TentInfoCard from '../../components/tentInfoCard';
import { useSelector } from 'react-redux';
import Header from '../../components/Shared/Header';
import { Card } from "@nextui-org/react";
import camera from '../../assets/icons/cctv-camera.png';
import CountUp from 'react-countup';


const Cameras = () => {

  const data = useSelector(state => state?.global?.TentList);

  console.log("data", data)
  let sumCapacity = 0;
  let sumStaying = 0;
  data.filter(value => value.is_arafa !== true).forEach(tent => {
    sumCapacity += tent.capacity;
    sumStaying += tent.realtime_data.staying;
});





  return (
    <div className="min-h-screen bg-gray-100 p-5">

      <Header title={'حصر اعداد الحجاج'} />
    <Card
          isPressable
          isHoverable
          variant='bordered'
          css={{ mw: "350px" , p: "$10", margin: "auto"}}
          >

          {/* staying */}
              {/* <p className="text-[26px]">اسم المركز</p> */}
              <div className="w-full  justify-between text-blue-800 items-end">
                <div className="flex gap-1 items-center justify-end ">
                    {/* <p className="text-[20px] ">{tent?.name} مركز</p> */}
                    <img src={camera} className="h-[25px] m-[10px]" alt="" />
                </div>
                <div className="flex justify-between items-center flex-row-reverse">
                <p className="text-base text-blue-800 ml-1">أعداد الحجاج</p>
                <p className="text-lg text-black font-semibold">
                  <CountUp
                    start={0}
                    end={sumCapacity
                      ? sumCapacity
                      : 0}
                    duration={2}
                    separator=","
                  />
                </p>
              </div>
              <div className="flex justify-between items-center flex-row-reverse">
                <p className="text-base text-blue-800 ml-1">‫المتواجدون</p>
                <p className="text-lg text-black font-semibold">
                  <CountUp
                    start={0}
                    end={
                      sumStaying
                        ? sumStaying
                        : 0
                    }
                    duration={2}
                    separator=","
                  />
                </p>
              </div>

              <div className="flex justify-between items-center flex-row-reverse">
                <p className="text-base text-blue-800 ml-1">المتبقي خارج المخيم</p>
                <p className="text-lg text-black font-semibold">
                  {' '}
                  {/* {`${sign}`} */}
                  <CountUp start={0} end={sumCapacity - sumStaying?sumCapacity - sumStaying : 0 } duration={2} separator="," />
                </p>
              </div>
              </div>
        </Card>
      <div className="tentList-container justify-center flex flex-row-reverse flex-wrap gap-3 box-border pt-3">
        {data ? data?.filter(tent => !tent?.is_arafa)?.map(tent => (
          <TentInfoCard key={tent.pk} tent={tent}></TentInfoCard>
        )) : <p>Loading</p>}
      </div>
    </div>
  );
};

export default Cameras;
