import React from 'react';
import 'leaflet/dist/leaflet.css';
import SiteInfo from '../components/SiteInfo';
import { useSelector } from 'react-redux';
import { Flex } from '@chakra-ui/react';
import { Box, CircularProgress } from '@mui/material';

export default function Dashboard() {
  const TentList = useSelector(state => state.global.TentList);
  // const TentData = useSelector(state => state.global.TentData);

  return (
    <Flex
      flexFlow={'column'}
      className="scrolling"
      w="full"
      h="full"
      p={5}
      
    >
      {(
        <SiteInfo tentList={TentList} />
      )}
    </Flex>
  );
}
