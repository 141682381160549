import React from 'react';

const Error = () => {
  return (
    <div className="grid place-content-center h-screen text-center gap-10">
      <div>
        <p className='text-9xl'>404</p>
        <p>Ooops! You weren't not supposed to see this</p>
        <p>
          This page you're looking for no longer exists. Return to the home page
          and remember. You haven't see anything.
        </p>
      </div>
    </div>
  );
};

export default Error;