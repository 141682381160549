import { Flex } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import SiteInfo from '../components/SensorMapLocation';
import SensorMapLocation from '../components/SensorMapLocation';

const Sensormap = () => {
const TentList = useSelector(state => state.global.TentList);

    return (
        <div className='p-5'>

        <SensorMapLocation tentList={TentList}/>

      </div>
    );
};

export default Sensormap;