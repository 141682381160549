import React, { useEffect, useState } from 'react';
import AdminLayout from '../Layouts/AdminLayout';
import { Flex, HStack, Select, Text, VStack } from '@chakra-ui/react';
import { Table, Input, Spacer, Button, Dropdown } from '@nextui-org/react';
import {
  RegisterCameraAPI,
  UpdateCameraAPI,
  cameraListAPI,
  tentListAPI,
} from '../../lib/apis';
import { useSelector } from 'react-redux';
import { useGetCameraListQuery, useGetTentListQuery } from '../../redux/api';

export default function CameraRegistration() {
  const data = useSelector(state => state?.global);
  const { data: camera, isLoading } = useGetCameraListQuery(undefined, {
    pollingInterval: 60000,
  });
  const { data: tentData } = useGetTentListQuery(undefined, {
    pollingInterval: 60000,
  });
  console.log('my tentdata', tentData);
  console.log('Niloy Camera: ', camera);
  console.log('Niloy TentData: ', data.TentData);
  const [tentList, setTentList] = useState([]);
  const [cameraList, setCameraList] = useState([]);
  const [pageLoad, setPageLoad] = useState(false);

  const [searchBySerial, setSearchBySerial] = useState('');
  const [camRegData, setCamRegData] = useState({
    sn: '',
    tent: '',
  });
  const [submitLoading, setSubmitLoading] = useState(false);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Calculate pagination indexes
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // Check if the camera array is null or undefined
  const filteredItems = camera
    ? camera?.filter(item => item?.sn?.includes(searchBySerial))
    : [];
  const currentItems = filteredItems?.slice(indexOfFirstItem, indexOfLastItem);
  // console.log('currentitems', currentItems);
  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  // const fetchData = async () => {
  //   await tentListAPI(setTentList);
  //   await cameraListAPI(setCameraList);
  // };

  // useEffect(() => {
  //   let interval;
  //   const fetchData = async () => {
  //     await tentListAPI(setTentList);
  //     await cameraListAPI(setCameraList);
  //   };

  //   const startFetchingData = () => {
  //     fetchData();
  //     interval = setInterval(fetchData, 6000);
  //   };

  //   const stopFetchingData = () => {
  //     clearInterval(interval);
  //   };

  //   startFetchingData();

  //   return () => {
  //     stopFetchingData();
  //   };
  // }, []);
  // console.log("cameraList: ", cameraList)
  // console.log("tentList: ", tentList)

  function formatDateTime(dateTimeString) {
    //const dateTime = new Date().toLocaleString("en-US", { timeZone: "Asia/Riyadh" });

    const dateTime = new Date(dateTimeString);
    const now = new Date(
      new Date().toLocaleString('en-US', { timeZone: 'Asia/Riyadh' })
    );
    const diffInMinutes = Math.floor((now - dateTime) / (1000 * 60));

    if (diffInMinutes < 1) {
      return 'الآن';
    } else if (diffInMinutes < 60) {
      if (diffInMinutes == 1) {
        return 'قبل دقيقة واحدة';
      }
      if (diffInMinutes == 2) {
        return 'قبل دقيقتين';
      } else {
        return 'قبل ' + diffInMinutes + ' دقائق';
      }
    } else if (diffInMinutes < 1440) {
      // 1440 minutes = 1 day
      const diffInHours = Math.floor(diffInMinutes / 60);
      if (diffInHours == 1) {
        return 'قبل ساعة واحدة';
      }
      if (diffInHours == 2) {
        return 'قبل ساعتين';
      } else {
        return 'قبل ' + diffInHours + ' ساعات';
      }
    } else if (diffInMinutes < 43200) {
      // 43200 minutes = 30 days = 1 month (approximately)
      const diffInDays = Math.floor(diffInMinutes / 1440);
      if (diffInDays == 1) {
        return 'قبل يوم واحد';
      }
      if (diffInDays == 2) {
        return 'قبل يومين';
      } else {
        return 'قبل ' + diffInDays + ' أيام';
      }
    } else if (diffInMinutes < 525600) {
      // 525600 minutes = 365 days = 1 year (approximately)
      const diffInMonths = Math.floor(diffInMinutes / 43200);
      return diffInMonths + ' months ago';
    } else {
      const diffInYears = Math.floor(diffInMinutes / 525600);
      return diffInYears + ' years ago';
    }
  }

  const HandleCamReg = () => {
    if (!camRegData.sn) {
      alert('Put the serial number');
    } else if (!camRegData.tent) {
      alert('Select a site');
    } else {
      setSubmitLoading(true);
      RegisterCameraAPI(camRegData, setCamRegData, setSubmitLoading);
    }
  };

  console.log('Camera', cameraList);
  const [selected, setSelected] = React.useState(new Set());

  const selectedValue = React.useMemo(
    () => Array.from(selected).join(', ').replaceAll('_', ' '),
    [selected]
  );

  return (
    <div className="">
      <AdminLayout>
        {pageLoad && (
          <Flex
            w="100%"
            h="100%"
            zIndex={'2'}
            position={'absolute'}
            bg="#757575"
            opacity={0.4}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Button
              variant={'outline'}
              isLoading
              color="white"
              border="none"
            ></Button>
          </Flex>
        )}
        <Flex
          w="full"
          h="full"
          flexFlow={'column'}
          justifyContent={'start'}
          mt={20}
        >
          <VStack spacing={4} w={'60%'} mx="auto">
            <HStack justifyContent={'space-between'} w="100%">
              <Text w="30%">Serial Number</Text>
              <Input
                type="text"
                placeholder="Enter serial number"
                value={camRegData.sn}
                onChange={e => {
                  setCamRegData({ ...camRegData, sn: e.target.value });
                }}
              />
            </HStack>
            <HStack justifyContent={'space-between'} w="100%">
              <Text w="30%">Site</Text>
              <Select
                className="bg-transparent border-2 py-2 px-4 rounded-md"
                variant="filled"
                w=""
                onChange={e => {
                  setCamRegData({ ...camRegData, tent: e.target.value });
                }}
                value={camRegData.tent}
              >
                <option value={''}>Select Site</option>
                {tentData
                  ?.slice()
                  ?.sort((a, b) => a.pk - b.pk)
                  ?.map((el, i) => {
                    return (
                      <option value={el.pk}>
                        {el.name}--{el.location}{' '}
                      </option>
                    );
                  })}
              </Select>
            </HStack>
            <Button
              className="bg-blue-600 w-full text-lg font-light"
              colorScheme="blue"
              onClick={HandleCamReg}
              isLoading={submitLoading}
            >
              Submit
            </Button>
          </VStack>

          <Flex w="full" mt={10} flexFlow={'column'} alignItems="center">
            <Input
              className=""
              width="60%"
              type="text"
              placeholder="Search By Serial Number"
              value={searchBySerial}
              onChange={e => {
                setSearchBySerial(e.target.value);
              }}
            />
            <Spacer y={2.5} />
            <div className="w-full">
              <table className="bg-white w-full border border-gray-200">
                <thead>
                  <tr>
                    <th className="py-2 px-4 border-b">Camera SN</th>
                    <th className="py-2 px-4 border-b">Site</th>
                    <th className="py-2 px-4 border-b">Last Update</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((item, i) => (
                    <tr className=" text-center" key={i}>
                      <td className="py-2 px-4 border-b">{item?.sn}</td>
                      <td className="py-2 px-4 border-b">
                        {item?.tent === null ? (
                          <select
                            className="bg-transparent border-2 p-2 rounded-lg"
                            onChange={e => {
                              setPageLoad(true);
                              UpdateCameraAPI(
                                item.pk,
                                item.sn,
                                e.target.value,
                                setPageLoad
                              );
                            }}
                            value={''}
                          >
                            <option>Select Site</option>
                            {tentData
                              ?.slice()
                              ?.sort((a, b) => a.pk - b.pk) // Sort the array based on pk
                              ?.map((e, i) => (
                                <option key={i} value={e.pk}>
                                  {e.name} -- {e.location}
                                </option>
                              ))}
                          </select>
                        ) : (
                          <select
                            className="bg-transparent border-2 p-2 rounded-lg"
                            onChange={e => {
                              setPageLoad(true);
                              UpdateCameraAPI(
                                item.pk,
                                item.sn,
                                e.target.value,
                                setPageLoad
                              );
                            }}
                            value={item?.tent?.id}
                          >
                            <option>Select Site</option>
                            {tentData?.map((e, i) => (
                              <option key={i} value={e.pk}>
                                {e.name} -- {e.location}
                              </option>
                            ))}
                          </select>
                        )}
                      </td>
                      {/* <td
                      className={`${
                        item.is_active ? 'text-green-600' : 'text-red-600'
                      } px-2 py-4 border-b`}
                      > */}
                      {/* {item?.is_active ? 'Online' : 'Offline'} */}
                      {/* {data?.TentData?.filter(i => i?.pk === item?.tent?.id)
                          ?.map(m => m?.cameras_data)
                          ?.flat()
                          ?.map(m => m?.last_update)?.[0]
                          ? formatDateTime(
                              data?.TentData?.filter(i => i?.pk === item?.pk)
                                ?.map(m => m?.cameras_data)
                                ?.flat()
                                ?.map(m => m?.last_update)?.[0]
                            )
                          : 'Unavailable'} */}

                      {/* {console.log(
                          'data',
                          data?.TentData?.filter(i => i?.pk === item?.pk)
                            ?.cameras_data
                        )} */}
                      {/* eikhane check hbe currentitem er pk tentdata er camera data er sathe. or pk er sathe */}
                      {/* </td> */}
                      <td className="px-2 py-4 border-b">
                        {/* {data?.TentData?.filter(i => i?.pk === item?.tent?.id)
                          ?.map(m => m?.cameras_data)
                          ?.flat()
                          ?.map(m => m?.last_update)
                          ?.map((lastUpdate, index) => (
                            <span key={index}>
                              {formatDateTime(lastUpdate)}
                              {index !==
                                data?.TentData?.filter(i => i?.pk === item?.pk)
                                  ?.map(m => m?.cameras_data)
                                  ?.flat()
                                  ?.map(m => m?.last_update)?.length -
                                  1 && ', '}
                            </span>
                          ))} */}
                        {item?.updated_at
                          ? formatDateTime(item?.updated_at)
                          : 'Unavailable'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="flex justify-center mt-4">
                <nav className="inline-flex">
                  <ul className="flex items-center">
                    {Array.from(
                      { length: Math.ceil(camera?.length / itemsPerPage) },
                      (_, index) => {
                        if (
                          index === 0 || // Always include the first page
                          index ===
                            Math.ceil(camera?.length / itemsPerPage) - 1 || // Always include the last page
                          (index >= currentPage - 2 && index <= currentPage + 2) // Include pages within the current page +/- 2 range
                        ) {
                          return (
                            <li key={index}>
                              <button
                                className={`px-3 py-2 mx-1 rounded-full ${
                                  currentPage === index + 1
                                    ? 'bg-blue-500 text-white'
                                    : 'bg-gray-200 text-gray-600'
                                }`}
                                onClick={() => paginate(index + 1)}
                              >
                                {index + 1}
                              </button>
                            </li>
                          );
                        } else if (
                          // Insert a "dot dot" for pages not included in the current range
                          index === currentPage - 3 ||
                          index === currentPage + 3
                        ) {
                          return (
                            <li key={index}>
                              <span className="px-3 py-2 mx-1">...</span>
                            </li>
                          );
                        } else {
                          return null; // Skip pages not included in the pagination range
                        }
                      }
                    )}
                  </ul>
                </nav>
              </div>
            </div>
          </Flex>
        </Flex>
      </AdminLayout>
    </div>
  );
}

// {/* <Table
//   bordered
//   shadow={true}
//   color="secondary"
//   aria-label="Example pagination  table"
//   css={{
//     height: '62vh',
//     minWidth: '100vh',
//   }}
//   selectionMode="single"
// >
//   <Table.Header>
//     <Table.Column>NAME</Table.Column>
//     <Table.Column>ROLE</Table.Column>
//     <Table.Column>STATUS</Table.Column>
//   </Table.Header>
//   <Table.Body>
//     {cameraList
//       ?.sort((a, b) =>
//         a.is_active === b.is_active ? 0 : a.is_active ? -1 : 1
//       )
//       ?.map(
//         (el, i) =>
//           el?.sn.includes(searchBySerial) && (
//             <Table.Row key={i}>
//               <Table.Cell>{el.sn}</Table.Cell>
//                 <Dropdown>
//                   <Dropdown.Button
//                     flat
//                     color="secondary"
//                     css={{ tt: 'capitalize' }}
//                     onChange={e => {
//                       setPageLoad(true);
//                       UpdateCameraAPI(
//                         el.pk,
//                         el.sn,
//                         e.target.value,
//                         setPageLoad,
//                         fetchData
//                       );
//                     }}
//                   >
//                     {selectedValue}
//                   </Dropdown.Button>
//                   <Dropdown.Menu
//                     aria-label="Single selection actions"
//                     color="secondary"
//                     disallowEmptySelection
//                     selectionMode="single"
//                     selectedKeys={selected}
//                     onSelectionChange={setSelected}
//                   >
//                     <Dropdown.Item key={i} value={null}>

//                     </Dropdown.Item>
//                     {tentList.map((e, i) => {
//                       return (
//                         <Dropdown.Item key={i} value={e.pk}>
//                           {e.name}--{e.location}
//                         </Dropdown.Item>
//                       );
//                     })}
//                   </Dropdown.Menu>
//                 </Dropdown>

//               <Select
//                 className="bg-transparent border-2 py-2 px-4"
//                 variant="filled"
//                 onChange={e => {
//                   setPageLoad(true);
//                   UpdateCameraAPI(
//                     el.pk,
//                     el.sn,
//                     e.target.value,
//                     setPageLoad,
//                     fetchData
//                   );
//                 }}
//                 value={el.tent}
//               >
//                 <option value={null}>Select Site</option>
//                 {tentList.map((e, i) => {
//                   return (
//                     <option value={e.pk}>
//                       {e.name}--{e.location}
//                     </option>
//                   );
//                 })}
//               </Select>

//               <Table.Cell>
//                 <span
//                   className={
//                     el.is_active ? 'text-green-600' : 'text-red-600'
//                   }
//                 >
//                   {el.is_active ? 'Online' : 'Offline'}
//                 </span>
//               </Table.Cell>
//             </Table.Row>
//           )
//       )}
//   </Table.Body>
//   <Table.Pagination
//     shadow
//     noMargin
//     align="center"
//     rowsPerPage={6}
//     onPageChange={page => console.log({ page })}
//   />
// </Table> */}
// {/* <Box
//   width="90%"
//   height="62vh"
//   // maxHeight="500px"
//   overflowY="auto"
//   className="scrolling pt-10"
// >
//   <Table className='w-1/2' colorScheme="teal">
//     <Thead>
//       <Tr>
//         <Th textAlign="center">Serial Number</Th>
//         <Th textAlign="center"> Tent </Th>
//         <Th textAlign="center"> Status </Th>
//       </Tr>
//     </Thead>
//     <Tbody>
//       {cameraList
//         ?.sort((a, b) =>
//           a.is_active === b.is_active ? 0 : a.is_active ? -1 : 1
//         )
//         ?.map(
//           (el, i) =>
//             el.sn.includes(searchBySerial) && (
//               <Tr key={Math.random()}>
//                 <Td textAlign="center">{el.sn}</Td>
//                 <Td textAlign="center">
//                   <Select
//                     variant="filled"
//                     w="100%"
//                     mx="auto"
//                     onChange={e => {
//                       setPageLoad(true);
//                       UpdateCameraAPI(
//                         el.pk,
//                         el.sn,
//                         e.target.value,
//                         setPageLoad,
//                         fetchData
//                       );
//                       // console.log(el.pk,el.sn,el.tent,e.target.value)
//                     }}
//                     value={el?.tent?.id}
//                   >
//                     <option value={null}>Select Site</option>
//                     {tentList.map((e, i) => {
//                       return (
//                         <option value={e.pk}>
//                           {e.name}--{e.location}
//                         </option>
//                       );
//                     })}
//                   </Select>
//                 </Td>
//                 <Td
//                   textAlign="center"
//                   color={el.is_active ? 'green' : 'red'}
//                   fontWeight={'semibold'}
//                 >
//                   {el.is_active ? 'Online' : 'Offline'}
//                 </Td>
//               </Tr>
//             )
//         )}
//     </Tbody>
//   </Table>
// </Box> */}
