import React, { useEffect, useRef, useState } from 'react';
import { tentDataAPI, tentListAPI } from '../lib/apis';

import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from 'react-leaflet';
import L from 'leaflet';
import {
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import marker from "../assets/location2.png";
import markerSelected from "../assets/location.png";

export default function SiteMarkedMap({ tentList, selectedTentId }) {
  return (
    <MapContainer
      // center={[21.411134, 39.892709]}
      center={[21.413059, 39.891643]}
      zoom={15}
      style={{ minHeight: '600px' }}
    >
      <TileLayer
       // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {tentList.map((el, i) => {  
        return (
          <Marker
            key={Math.random()}
            position={[el.lat, el.long]}
            icon={L.icon({
              iconUrl: el.pk == selectedTentId ? markerSelected: marker,
              iconSize: el.pk == selectedTentId ? [50, 50]: [35, 35],
              iconAnchor: [16, 32],
            })}
          >
            <Popup>
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                    <Th textAlign={'center'} className='text-blue-800 p-2 text-xl'>اعداد الحجاج</Th>

                      <Th textAlign={'center'} className='text-blue-800 p-2 text-xl'>المتواجدون</Th>
                      <Th textAlign={'center'} className='text-blue-800 p-2 text-xl'> المركز</Th>

                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                    <Td textAlign={'center'} className='text-black p-2 text-xl'>
                        {el.capacity ? el.capacity : 'Unavailable'}
                      </Td>
                      <Td textAlign={'center'} className='text-black p-2 text-xl'>
                        {(el?.realtime_data?.totals_in-el?.realtime_data?.totals_out) > 0 ? el?.realtime_data?.totals_in-el?.realtime_data?.totals_out : 0}
                      </Td>
                      <Td textAlign={'center'} className='text-black p-2 text-xl'>{el.name}</Td>

                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Popup>
          </Marker>
        );
      })}
    </MapContainer>
  );
}
