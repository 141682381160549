import axios from 'axios';
import { getCookie, setCookie } from './Cookies';

// const HostURL = 'localhost:8000/';
const HostURL = 'https://baitguests.com:8000/';
const AuthToken = getCookie('user')
  ? JSON.parse(getCookie('user'))?.data.token
  : '';
// localhost:8000/
// https://albaitpilgrims.com/

//Comparer Function
function GetSortOrder(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}

export async function LoginAPI(data, setIsLoading) {
  let formdata = new FormData();
  formdata.append('email', data.email);
  formdata.append('password', data.password);

  await axios
    .post(`${HostURL}api/camera/login`, formdata, {
      headers: {
        accept: 'application/json',
      },
    })
    .then(response => {
      if (response.data.response === 'error') {
        alert(response.data.error_message);
      } else {
        console.log(JSON.stringify(response));
        // sessionStorage.setItem('user', JSON.stringify(response));
        console.log('login data', response.data);
        setCookie('user', JSON.stringify(response));
        window.location.reload(true);
      }
      setIsLoading(false);
    });
}

export async function RegisterSiteAPI(data, setsiteReg, setSubmitLoading) {
  let formdata = new FormData();
  formdata.append('name', data.name);
  formdata.append('lat', data.lat);
  formdata.append('long', data.long);
  formdata.append('location', '');
  formdata.append('capacity', data.capacity);

  await axios
    .post(`${HostURL}api/camera/tent/register`, formdata, {
      headers: {
        accept: 'application/json',
        Authorization: `Token ${AuthToken}`,
      },
    })
    .then(response => {
      if (response.data.response === 'success') {
        setsiteReg({
          name: '',
          location: '',
          lat: '',
          long: '',
        });
        alert('Site added');
      } else {
        // alert(`Name - ${response.data.name[0]}\nLocation - ${response.data.location[0]}\nLattitude - ${response.data.lat[0]}\nLongitude - ${response.data.long[0]}\n
        // `);
        alert(JSON.stringify(response.data));
      }
      setSubmitLoading(false);
    });
} /*  */

export async function RegisterCameraAPI(data, setCamRegData, setSubmitLoading) {
  let formdata = new FormData();
  formdata.append('sn', data.sn);
  formdata.append('tent', data.tent);

  await axios
    .post(`${HostURL}api/camera/camera/register`, formdata, {
      headers: {
        accept: 'application/json',
        Authorization: `Token ${AuthToken}`,
      },
    })
    .then(response => {
      if (response.data.response === 'success') {
        setCamRegData({
          sn: '',
          tent: '',
        });
        alert('Camera added');
        window.location.reload();
      } else {
        alert(response.data);
      }
      setSubmitLoading(false);
    });
}

export async function RegisterSensorAPI(
  data,
  setSensorRegData,
  setSubmitLoading
) {
  let formdata = new FormData();
  formdata.append('sn_list', data.sn);
  formdata.append('tent', data.tent);

  await axios
    .post(`${HostURL}api/camera/sensor/multiple/create`, formdata, {
      headers: {
        accept: 'application/json',
        Authorization: `Token ${AuthToken}`,
      },
    })
    .then(response => {
      if (response.data.response === 'success') {
        setSensorRegData({
          sn: '',
          tent: '',
        });
        alert('Sensor added');
        window.location.reload();
      } else {
        alert(response.data);
      }
      setSubmitLoading(false);
    });
}

export async function RegisterUserAPI(data, setUserRegData, setSubmitLoading) {
  let formdata = new FormData();
  formdata.append('username', data.username);
  formdata.append('email', data.email);
  formdata.append('password', data.password);
  formdata.append('password2', data.password2);
  formdata.append('tent_list', data.tent_list);

  await axios
    .post(`${HostURL}api/camera/register`, formdata, {
      headers: {
        accept: 'application/json',
        Authorization: `Token ${AuthToken}`,
      },
    })
    .then(response => {
      if (response.data.response === 'success') {
        setUserRegData({
          username: '',
          email: '',
          password: '',
          password2: '',
          tent_list: '',
        });
        alert('User added');
      } else {
        alert(response.data);
      }
      setSubmitLoading(false);
    });
}

export async function UpdateCameraAPI(pk, sn, tent, setPageLoad) {
  let formdata = new FormData();
  formdata.append('sn', sn);
  formdata.append('tent', tent);

  await axios
    .put(`${HostURL}api/camera/camera/${pk}/update`, formdata, {
      headers: {
        accept: 'application/json',
        Authorization: `Token ${AuthToken}`,
      },
    })
    .then(response => {
      if (response.data.response === 'success') {
        alert('Camera Updated');
      } else {
        alert(response.data);
      }
      setPageLoad(false);
      // fetchData();
    });
}
export async function UpdateSensorAPI(pk, sn, tent, setPageLoad, fetchData) {
  let formdata = new FormData();
  formdata.append('sn', sn);
  formdata.append('tent', tent);

  await axios
    .put(`${HostURL}api/camera/sensor/update/${pk}`, formdata, {
      headers: {
        accept: 'application/json',
        Authorization: `Token ${AuthToken}`,
      },
    })
    .then(response => {
      if (response.data.response === 'success') {
        alert('Camera Updated');
      } else {
        alert(response.data);
      }
      setPageLoad(false);
      fetchData();
    });
}
export async function tentListAPI(setTentList) {
  await axios
    .get(`${HostURL}api/camera/tent_list`, {
      headers: {
        accept: 'application/json',
        Authorization: `Token ${AuthToken}`,
      },
    })
    .then(response => {
      // console.log("response.data: ",response.data)
      const sortedTentList = response.data.sort(GetSortOrder('pk'));
      // console.log("tentListSorted: ",sortedTentList)
      //clearInterval(interval);
      setTentList(sortedTentList);
    });
}
export async function upDateTentListApi(pk, name, radio, date) {
  const runUpdate = async () => {
    let formdata = new FormData();
    formdata.append('air_condition', radio);
    // formdata.append('air_condition_update_time', date);
    console.log('props:', pk, name, radio, date);
    // formdata.append('sn', sn);
    // formdata.append('tent', tent);
    if (pk) {
    }
    await axios
      .put(`${HostURL}api/camera/tent/${pk}/update`, formdata, {
        headers: {
          accept: 'application/json',
          Authorization: `Token ${AuthToken}`,
        },
      })
      .then(response => {
        if (response.data.response === 'success') {
          console.log('response.data: ', response.data);
          alert(`Site ${name} is updated successfully`);
        } else {
          console.log(response.data);
        }
        // setPageLoad(false);
        // fetchData();
      });
  };
  pk && runUpdate();
}

export async function orderListAPI(setOrderList) {
  await axios
    .get(`https://pet-force-server-pulok-thedeveloper.vercel.app/cors-proxy`, {
      //https://pet-force-server-pulok-thedeveloper.vercel.app/cors-proxy
      headers: {
        accept: 'application/json',
        Authorization: `Token ${AuthToken}`,
      },
    })
    .then(response => {
      // console.log('weight list', response.data);
      setOrderList(response.data);
    });
}

export async function cameraListAPI(setCameraList) {
  await axios
    .get(`${HostURL}api/camera/camera_list`, {
      headers: {
        accept: 'application/json',
        Authorization: `Token ${AuthToken}`,
      },
    })
    .then(response => {
      setCameraList(response.data);
    });
}
export async function sensorListAPI(setSensorList) {
  await axios
    .get(`${HostURL}api/camera/sensor_list`, {
      headers: {
        accept: 'application/json',
        Authorization: `Token ${AuthToken}`,
      },
    })
    .then(response => {
      setSensorList(response.data);
    });
}

export async function tentDataAPI(tentID, setSiteData) {
  await axios
    .get(`${HostURL}api/camera/tent_realtime_data/${tentID}`, {
      headers: {
        accept: 'application/json',
        Authorization: `Token ${AuthToken}`,
      },
    })
    .then(response => {
      setSiteData(response.data);
    });
}
