import { Popover } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';

const CustomMarker = (props) => {



    const [inputValue, setInputValue] = useState('');
    // const { TextArea } = Input;

    let formattedDate = moment(props?.updated_at).locale('en').fromNow();
    const containerClass = props?.online ? "custom-marker-container1" : "custom-marker-container0";

    return (
        <>
        {
            props?.top !== null && props?.left !== null &&         <div>
            <Popover content={(
            <div>
            <p>Temperature: {props?.tempareture/10}°C</p>
            <p>Humadity: {props?.humidity}%</p>
            <p>Updated Time: {formattedDate}</p>
            <p>Device Status:  {props?.online? "Online":"Offline"}</p>

                {/* onSubmit={onSubmit} */}
                <form >
                {/* <TextArea /> */}
                {/* <Button style={{marginTop:'10px'}} type="primary">Post</Button> */}
                </form>
            </div>
      )} title={`Device Name: ${props?.name}`} trigger="click">
          <div className={`${containerClass}`}>
            <div className="custom-marker">
              <span><strong>{parseInt(props.tempareture/10)}°C</strong></span>
            </div>
            </div>
        </Popover>
        </div>
        }
        </>
    );
};

export default CustomMarker;