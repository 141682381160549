import { createSlice } from '@reduxjs/toolkit';
import sortCompare from '../util/propSort';

const initialState = {
  TentList: undefined,
  CameraList: undefined,
  SensorList: undefined,
  OrderList: undefined,
  TentData: undefined,
  Arafa: undefined,
  site: localStorage.getItem('camera')
    ? JSON.parse(localStorage.getItem('camera'))
    : undefined,
  maximumTemp: undefined,
  minimumTemp: undefined,
  maxHumadity: undefined,
  minHumadity: undefined,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setTentList: (state, action) => {
      // console.log(action.payload);
      // const data = action?.payload?.map(data => data)?.sort(sortCompare('name'));
      const data = action?.payload?.map(data => data);
      state.site = localStorage.getItem('camera')
        ? JSON.parse(localStorage.getItem('camera'))
        : data[0];
      state.TentList = data;
      state.Arafa = action?.payload?.filter(data => data.is_arafa);
    },
    setCameraList: (state, action) => {
      state.CameraList = action.payload;
    },
    setSensorList: (state, action) => {
      state.SensorList = action.payload;
    },
    setOrderList: (state, action) => {
      state.OrderList = action.payload;
    },
    setTentData: (state, action) => {
      state.TentData = action.payload;
    },
    setSite: (state, action) => {
      state.site = action.payload;
    },
    setMaximumTemp: (state, action) => {
      state.maximumTemp = action.payload;
    },
    setMinimumTemp: (state, action) => {
      state.minimumTemp = action.payload;
    },
    setMaxHumadity: (state, action) => {
      state.maxHumadity = action.payload;
    },
    setMinHumadity: (state, action) => {
      state.minHumadity = action.payload;
    },
  },
});

export default globalSlice;
export const {
  setTentList,
  setCameraList,
  setOrderList,
  setSensorList,
  setTentData,
  setSite,
  setMaximumTemp,
  setMinimumTemp,
  setMinHumadity,
  setMaxHumadity,
} = globalSlice.actions;
