import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
 
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Box, Flex, HStack,
  Input,
  Text, } from '@chakra-ui/react';

import marker from "../assets/locationMarker.png";
import { useState } from 'react';

const LocationSelector = ({ selectedLocation, setSelectedLocation }) => {
  const handleMapClick = e => {
    setSelectedLocation(e.latlng);
  };


  return (
    <Box className='bg-white p-10 border border-gray-300 rounded-lg hover:shadow-2xl' w="60%" mx="auto">
      <HStack justifyContent={'space-between'} w="100%" my={10}>
            <Text w="30%">Latitude: {selectedLocation?.lat.toFixed(6)}, <br />Longitude:{' '}
              {selectedLocation?.lng.toFixed(6)}</Text>
          </HStack>
      <MapContainer
        center={[21.411134, 39.892709]}
        zoom={15}
        style={{ height: '400px' }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <MapClickHandler handleClick={handleMapClick} />
        {selectedLocation && (
          <Marker
            position={selectedLocation}
            zoom={20}
            icon={L.icon({
              iconUrl:marker,
              iconSize: [26, 32],
              iconAnchor: [16, 32],
            })}
          >
            <Popup>
              Latitude: {selectedLocation.lat.toFixed(6)}, Longitude:{' '}
              {selectedLocation.lng.toFixed(6)}
            </Popup>
          </Marker>
        )}
      </MapContainer>
      {/* {selectedLocation && (
        <div>
          Selected Location: {selectedLocation.lat.toFixed(6)},{' '}
          {selectedLocation.lng.toFixed(6)}
        </div>
      )} */}
    </Box>
  );
};

const MapClickHandler = ({ handleClick }) => {
  useMapEvents({
    click: handleClick,
  });

  return null;
};

export default LocationSelector;
