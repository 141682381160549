import React from 'react';
import {
  Box,
  Container,
  Typography,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  RadioGroup,
  Radio,
  Button,
} from '@mui/material';
import { useSelector } from 'react-redux';
import logo from '../assets/logo.png';
import { useState } from 'react';
import moment from 'moment/moment';
import { useGetTentListQuery } from '../redux/api';
import { useNavigate } from 'react-router-dom';
import { Image } from '@chakra-ui/react';
import { deleteCookie } from '../lib/Cookies';
import { upDateTentListApi } from '../lib/apis';

const AirQuality = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const TentList = useSelector(state => state?.global?.TentList);
  const { data: tentData } = useGetTentListQuery(undefined, {
    pollingInterval: 60000,
  });
  console.log('tentlits, ', TentList);
  console.log('tentdata', tentData);
  const [site, setSite] = useState(TentList?.[0]);

  const [radio, setRadio] = useState(0);
  // console.log(theme);
  // const lastSubmit = radio === 0 ? 'Poor' : radio === 1 ? 'Medium' : radio === 2 ?  'Good' : null;
  const lastSubmit =
    radio === 0 ? 'سيء' : radio === 1 ? 'متوسط' : radio === 2 ? 'جيد' : null;
  console.log('site in air', site, radio);

  // Site is update at present time
  return (
    <>
      <div className="mt-12">
        <div className="flex justify-center items-center">
          <Image
            className=""
            src={logo}
            h="100px"
            //w="150px"
            py={3}
            cursor={'pointer'}
          />
        </div>
        <Container maxWidth="sm">
          <Box
            sx={{
              bgcolor: '#fff',
              width: '350px',
              pt: 4,
              mx: 'auto',
              borderRadius: '0.55rem',
              my: 4,
              px: 2,
              py: 4,
            }}
          >
            <Typography variant="h4" sx={{ textAlign: 'center', pb: 4 }}>
              {/* Air Quality */}
              جودة الهواء
            </Typography>
            {/* <Typography>Select Site</Typography> */}
            <Box
              sx={{
                maxWidth: '250px',
                bgcolor: '#fff',
                border: false,
                borderRadius: '0.55rem',
                mb: 4,
                mx: 'auto',
              }}
            >
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  color={theme.black}
                  size="20px"
                   sx={{fontSize: '12px', paddingBottom: '25px' }}
                >
                  المركز
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={JSON.stringify(site)}
                  label="اختر المركز"
                  defaultValue=""
                  onChange={e => {
                    // console.log(e.target.value);
                    setSite(JSON.parse(e.target.value));
                    // localStorage.setItem('camera', e.target.value);
                  }}
                  //   renderValue={site =>
                  //     `${JSON.parse(site)?.name} ${JSON.parse(site)?.location}`
                  //   }
                  
                  sx={{ fontSize: '1.5rem', borderRadius: '0.55rem', width: '100%' }}
                >
                  {tentData
                    ?.slice()
                    ?.sort((a, b) => a.pk - b.pk)
                    ?.map((el, i) => {
                      return (
                        <MenuItem
                          value={JSON.stringify(el)}
                          key={el?.pk}
                          sx={{ fontSize: '1.4rem', py: 1 }}

                        >
                          <p>{el.name} مركز </p>
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
            <Box
              component={'div'}
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                py: 1,
                gap: 3,
                
              }}
            >
              <RadioGroup
                row
                value={radio}
                onChange={e => setRadio(e.target.value)}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{
                  borderRadius: '0.55rem',
                  border: 1,
                  px: 1,
                  py: 1,
                }}
              >
                <FormControlLabel value={0} control={<Radio size="large" />} label={<Typography variant="h5" >سيء</Typography>} />
                <FormControlLabel value={1} control={<Radio size="large" />} label={<Typography variant="h5" >متوسط</Typography>}/>
                <FormControlLabel value={2} control={<Radio size="large" />} label={<Typography variant="h5" >جيد</Typography>} />
              </RadioGroup>
              <Button
                variant="contained"
                sx={{ bgcolor: theme.myColors.blue, fontSize: 15 }}
                size="large"
                onClick={() => {
                  // update the tentlistapi
                  upDateTentListApi(
                    site?.pk,
                    site?.name,
                    radio,
                    moment().format('YYYY-MM-DDTHH:mm:ss.SSSSSS')
                  );
                }}
              >
                ارسال
              </Button>
              {/* <Typography
                variant="always"
                sx={{
                  fontSize: 20,
                  border: '2px',
                  borderRadius: '10px',
                  borderColor: '#d68e35',
                  padding: '10px',
                }}
              >
                {lastSubmit ? (
                  <div>
                    آخر تحديث {lastSubmit} at{' '}
                    {moment(Date.now())
                      .utcOffset(6)
                      .format('hh:mm:ss A, DD MMMM, YYYY')}
                  </div>
                ) : (
                  'Site is update at present time'
                )}
              </Typography> */}
            </Box>
          </Box>
        </Container>
        {/* if (actionKey === 'logout') {
                    deleteCookie('user');
                    // window.location.reload(true);
                    navigate('/');
                    localStorage.clear();
                  } */}
        {/* Logout button */}
        <div className="flex justify-center items-center pb-5">
          <Button
            variant="contained"

            sx={{ bgcolor: theme.myColors.yellow, fontSize: 10 }}
            //className="bg-yellow-500 hover:bg-yellow-600 text-white font-medium py-2 px-4 rounded-md"
            onClick={() => {
              deleteCookie('user');
              // window.location.reload(true);
              navigate('/');
              localStorage.clear();
            }}
          >
            Logout
          </Button>
        </div>
      </div>
    </>
  );
};

export default AirQuality;
