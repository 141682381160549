import React from 'react';
import './Navbar.css';
import {
  Image,
} from '@chakra-ui/react';
import logo from '../../assets/logo.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { deleteCookie, getCookie } from '../../lib/Cookies';
import { RiAdminFill, RiFullscreenFill } from 'react-icons/ri';
import { BsFillGearFill } from 'react-icons/bs';
import avatar from '../../assets/avatar.png';
import settingsIcon from '../../assets/icons/settings.png'
import expandIcon from '../../assets/icons/expand (1).png'

import { Navbar, Text, Avatar, Dropdown } from '@nextui-org/react';

const MainNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleFullScreenToggle = () => {
    if (document.fullscreenEnabled) {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    }
  };

  const collapseItems = ['Log Out'];
  return (
    <>
      <div className="w-full flex justify-between sticky top-0 z-[1000000] bg-[#F7F7F7]">
        <Navbar isBordered variant="sticky">
          <Navbar.Collapse>
            {collapseItems.map((item, index) => (
              <Navbar.CollapseItem
                key={item}
                activeColor="secondary"
                css={{
                  color: index === collapseItems.length - 1 ? '$error' : '',
                }}
                isActive={index === 2}
              >
                <Link
                  color="inherit"
                  css={{
                    minWidth: '100%',
                  }}
                  href="#"
                >
                  {item}
                </Link>
              </Navbar.CollapseItem>
            ))}
          </Navbar.Collapse>
          <Navbar.Content
            css={{
              '@xs': {
                w: '12%',
                jc: 'flex-start',
              },
            }}
          >
            <Dropdown placement="bottom-right">
              <Navbar.Item>
                <Dropdown.Trigger>
                  {/* <Avatar
                    bordered
                    as="button"
                    color="secondary"
                    size="md"
                    src="https://i.pravatar.cc/150?u=a042581f4e29026704d"
                  /> */}
                  <Avatar
                    bordered
                    as="button"
                    color="secondary"
                    size="lg"
                    src={avatar}
                  ></Avatar>
                </Dropdown.Trigger>
              </Navbar.Item>
              <Dropdown.Menu
                aria-label="User menu actions"
                color="secondary"
                onAction={actionKey => {
                  if (actionKey === 'logout') {
                    deleteCookie('user');
                    // window.location.reload(true);
                    navigate('/');
                    localStorage.clear();
                  }
                }}
              >
                {/* <Dropdown.Item key="profile" css={{ height: '$18' }}>
                  <Text b color="#CA8A04" css={{ d: 'flex' }}>
                    Signed in as
                  </Text>
                  <Text b color="#CA8A04" css={{ d: 'flex' }}>
                    zoey@example.com
                  </Text>
                </Dropdown.Item> */}
                <Dropdown.Item key="logout" withDivider color="error">
                  Log Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div>
              <button
                className="cursor-pointer"
                onClick={() => {
                  handleFullScreenToggle();
                }}
              >
            <img src={expandIcon} alt="" width={18}/>
                
              </button>
            </div>
            <button
              onClick={() => {
                navigate('/adminsite/userregistration');
              }}
            >
            <img src={settingsIcon} alt="" width={18}/>
            </button>
          </Navbar.Content>
          <Navbar.Content
          enableCursorHighlight
          activeColor="secondary"
          hideIn="xs"
          variant="highlight-rounded"
        >
        {/* <p className='bg-[#EADCF8] text-[#914BD1] font-semibold rounded-full p-3'>{location.pathname.split('/')[1].toUpperCase()}</p> */}
        </Navbar.Content>
          <Navbar.Brand
            
          >
            <Image
              className=""
              src={logo}
              h="70px"
              w="auto"
              py={3}
              cursor={'pointer'}
              onClick={() => {
                navigate('/home');
              }}
            />
          </Navbar.Brand>
        </Navbar>
      </div>
    </>
  );
};

export default MainNavbar;
