import React from 'react'

const Header = ({title}) => {
  return (
    <div className='w-full'>
        <h1 className='w-full text-center text-4xl pb-10 pt-4 text-[#d68e35]'>{title}</h1>
    </div>
  )
}

export default Header