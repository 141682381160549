import { Box, Button, Flex, Image, Input, VStack } from '@chakra-ui/react';
import React from 'react';
import { useState } from 'react';
import { LoginAPI } from '../lib/apis';
import logo from '../assets/Logo.jpg';
import loginbackground from '../assets/albaitlogin.jpg';

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });
  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await LoginAPI(loginData, setIsLoading);
  };

  return (
    <>
      <Box
        backgroundImage={loginbackground}
        backgroundPosition="start"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        display="grid"
      >
        <Flex
          w="100vw"
          h="100vh"
          justifyContent={'center'}
          alignItems={'center'}
        >
          <VStack
            boxShadow="lg"
            p={10}
            border="1px"
            borderColor={'ash.100'}
            w={'450px'}
            bg="#F7F7F7"
            rounded="3xl"
          >
            <Image src={logo} w={40} />
            <Input
              p="10px 20px"
              w="300px"
              type="email"
              placeholder="Email"
              border="none"
              borderColor={'ash.600'}
              value={loginData.email}
              backgroundColor="transparent"
              borderBottom="1px"
              outline="none"
              onChange={e => {
                setLoginData({ ...loginData, email: e.target.value });
              }}
            />
            <Input
              p="10px 20px"
              w="300px"
              type="password"
              placeholder="Password"
              border="none"
              borderColor={'ash.600'}
              value={loginData.password}
              backgroundColor="transparent"
              borderBottom="1px"
              outline="none"
              onChange={e => {
                setLoginData({ ...loginData, password: e.target.value });
              }}
            />
            <br/>
            <Button 
            border="1px solid #000"
            padding="5px 40px"
             onClick={handleLogin} isLoading={isLoading}>
              Login
            </Button>
          </VStack>
        </Flex>
      </Box>
    </>
  );
}
