export default function formatDateTime(dateTimeString) {
  //const dateTime = new Date().toLocaleString("en-US", { timeZone: "Asia/Riyadh" });

  const dateTime = new Date(dateTimeString);
  const now = new Date(
    new Date().toLocaleString('en-US', { timeZone: 'Asia/Riyadh' })
  );
  const diffInMinutes = Math.floor((now - dateTime) / (1000 * 60));

  if (diffInMinutes < 1) {
    return 'الآن';
  } else if (diffInMinutes < 60) {
    if (diffInMinutes == 1) {
      return 'قبل دقيقة واحدة';
    }
    if (diffInMinutes == 2) {
      return 'قبل دقيقتين';
    } else {
      return 'قبل ' + diffInMinutes + ' دقائق';
    }
  } else if (diffInMinutes < 1440) {
    // 1440 minutes = 1 day
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours == 1) {
      return 'قبل ساعة واحدة';
    }
    if (diffInHours == 2) {
      return 'قبل ساعتين';
    } else {
      return 'قبل ' + diffInHours + ' ساعات';
    }
  } else if (diffInMinutes < 43200) {
    // 43200 minutes = 30 days = 1 month (approximately)
    const diffInDays = Math.floor(diffInMinutes / 1440);
    if (diffInDays == 1) {
      return 'قبل يوم واحد';
    }
    if (diffInDays == 2) {
      return 'قبل يومين';
    } else {
      return 'قبل ' + diffInDays + ' أيام';
    }
  } else if (diffInMinutes < 525600) {
    // 525600 minutes = 365 days = 1 year (approximately)
    const diffInMonths = Math.floor(diffInMinutes / 43200);
    return diffInMonths + ' months ago';
  } else {
    const diffInYears = Math.floor(diffInMinutes / 525600);
    return diffInYears + ' years ago';
  }
}
