import SensorCard from '../components/SensorCard';
import { useSelector } from 'react-redux';
import Header from '../components/Shared/Header';
import getEnvironmentData from '../util/getEnvironment';

const Sensor = () => {
  const TentData = useSelector(state => state?.global?.TentData);
  const Arafa = useSelector(state => state?.global?.Arafa);
  const arafa = Arafa?.map(tent => tent?.pk);

  // console.log("this is tentdata",TentData[0].sensor_data)
  const data = TentData?.filter((data) => (data?.sensor_data?.length > 0))?.map((data) => {
    const environment = getEnvironmentData(data?.sensor_data);
    return {
      ...data,
      environment,
    }
  }).sort((a,b) => {
    return b.environment.maximumTemp - a.environment.maximumTemp;
  });
  const higher = data?.slice(0,4)?.map((data) => {
    return data.pk;
  });

  const lower = data?.slice(-4)?.map((data) => {
    return data.pk;
  });
  
  // console.log(lower);
  const finalData = data?.map((tent) => {
    if(higher.indexOf(tent?.pk) !== -1){
      return {
        ...tent,
        isRed: true,
      }
    }
    if(lower?.indexOf(tent?.pk) !== -1) {
      return {
        ...tent,
        isGreen: true,
      }
    }
    return tent;
  }).concat(TentData?.filter((tent) => tent?.sensor_data?.length === 0))?.filter(tent => (arafa.indexOf(tent?.pk) === -1))?.sort((a,b) => {
    return a.pk - b.pk;
  });
  // console.log(higher);
  // console.log(lower);

  // console.log(finalData);

  return (
    <div className="min-h-screen bg-gray-100 p-5">
      <Header title={'المؤشرات البيئية'} />
      <div className="tentList-container flex flex-row-reverse justify-center flex-wrap gap-3">
        {finalData ? finalData?.map((tent, index) => (
          <SensorCard key={tent.pk} siteData={tent} />
        )): <p>Loading</p>}
      </div>
    </div>
  );
};

export default Sensor;

